.footer {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 80px;
  background: linear-gradient(213.19deg, #72D2FE 1.48%, #389CD3 50.32%);
  color: $color-white;

  // Top Bar
  &__top {
    display: flex;

    &-left {
      width: 360px;

      .link {
        margin-top: 20px;
      }
    }

    &-right {
      width: calc(100% - 360px);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 120px;

      .link {
        display: none;
      }

      .contact-info-list {
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;

        .list-item {
          display: inline-flex;
          align-items: center;

          .icon {
            margin-right: 10px;

            svg {
              max-height: 20px;
              width: auto;
              display: block;
              object-fit: contain;
            }
          }

          .caption {
            font-family: $font-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 1;
            color: $color-white;
          }
        }
      }
    }
  }

  // Form
  .form-wrap {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 80px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba($color-white, .3);
    display: none;
    &.active{
      display: flex;
    }
  }
  .form {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .form-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: -10px;
      margin-right: -10px;

      .form-group {
        flex-grow: 1;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;

        label {
          font-family: $font-primary;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 1.25;
          letter-spacing: 0.05em;
          color: $color-white;
          margin-bottom: 10px;
          display: inline-block;
          cursor: pointer;
        }

        input {
          display: block;
          height: 60px;
          width: 100%;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid #AFE1F0;
          padding-left: 20px;
          padding-right: 15px;
          font-family: $font-primary;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          color: $color-white;
          letter-spacing: 0.05em;

          &::placeholder {
            font-family: $font-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: rgba($color-white, .4);
          }
        }

        textarea {
          display: block;
          min-height: 120px;
          resize: vertical;
          max-height: 250px;
          width: 100%;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid #AFE1F0;
          padding: 15px 20px;
          font-family: $font-primary;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          color: $color-white;
          letter-spacing: 0.05em;

          &::placeholder {
            font-family: $font-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: rgba($color-white, .4);
          }
        }
      }
    }

    .submit-group {
      margin-top: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  // Bot
  &__bot {
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    line-height: 1.55;

    &-col {
      width: calc(100% / 3);
      padding-bottom: 40px;
      .caption {
        font-family: $font-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $color-white;
        margin-bottom: 20px;
        display: block;
      }

      ul {
        list-style-type: none;
        li {
          list-style: none;
        }
      }

      ul li, p , address{
        font-family: $font-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 1.7;
        letter-spacing: 0.05em;
        color: rgba($color-white,.7);
      }


      &:nth-child(1) {
        text-align: left;
      }

      &:nth-child(2) {
        text-align: center;
      }

      &:nth-child(3) {
        text-align: right;
      }
    }
  }

  // Copyright
  .copyright{
    text-align: center;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba($color-white,.4);
  }

  @media (max-width: 1199px) {
    &__top {
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
      &-right {
        height: auto;
        display: block;

        .contact-info-list {
          padding-top: 10px;
          flex-direction: column;
          align-items: flex-start;

          .list-item {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    &__top {
      flex-wrap: wrap;

      &-left {
        width: 100%;
        max-width: 100%;

        .section-title {
          text-align: center;
          max-width: 250px;
          margin-left: auto;
          margin-right: auto;
        }

        .link {
          display: none;
        }
      }

      &-right {
        width: 100%;
        max-width: 100%;

        .contact-info-list {
          margin-top: 40px;
          margin-bottom: 12px;
          justify-content: center;
          align-items: flex-start;

          .list-item {
            justify-content: center;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .link {
          display: inline-flex;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  @media (max-width: 666px) {
    &__bot{
      &-col {
        width: 100%;
        max-width: 100%;
        text-align: center!important;
        padding-bottom: 45px;
      }
    }
  }

}

.footer-light {
  padding-top: 260px;
  width: 100%;
  padding-bottom: 80px;
  background:$color-white;
  color: $color-dark;


  &__container{
    &:before{
      border-bottom: 1px solid #C0C0C0;
      width: 100%;
      display: block;
      content: '';
    }
  }


  // Bot
  &__bot {
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    line-height: 1.55;

    &-col {
      width: calc(100% / 3);
      padding-bottom: 40px;
      .caption {
        font-family: $font-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $color-dark;
        margin-bottom: 20px;
        display: block;
      }

      ul {
        list-style-type: none;
        li {
          list-style: none;
        }
      }

      ul li, p , address{
        font-family: $font-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 1.7;
        letter-spacing: 0.05em;
        color: rgba($color-dark,.7);
      }


      &:nth-child(1) {
        text-align: left;
      }

      &:nth-child(2) {
        text-align: center;
      }

      &:nth-child(3) {
        text-align: right;
      }
    }
  }

  // Copyright
  .copyright{
    text-align: center;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba($color-dark,.4);
  }


  @media (max-width: 1199px) {
    padding-top: 120px;
  }

  @media (max-width: 666px) {
    padding-top: 0;
    &__container{
      &:before{
        display: none;
      }
    }
    &__bot{
      &-col {
        width: 100%;
        max-width: 100%;
        text-align: center!important;
        padding-bottom: 45px;
      }
    }
  }

}