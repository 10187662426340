.nav-main {
  position: absolute;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .left-part {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .right-part {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .menu {
    display: flex;
    flex-wrap: wrap;

    & > li {
      position: relative;
      margin-left: 40px;
      padding: 10px 0;
      display: flex;

      & > a {
        display: flex;
        font-family: $font-secondary;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $color-white;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 5px;

          svg {
            @include transition;
            height: 5px;
            width: auto;
            display: block;
            object-fit: contain;
          }
        }
      }

      .sub-menu {
        left: -28px;
        top: 99%;
        position: absolute;
        width: 240px;
        background-color: $color-white;
        padding-left: 30px;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 15px;
        @include transition;
        opacity: 0;
        pointer-events: none;
        transform: translateY(15px);

        li {
          a {
            font-family: $font-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 2.5;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $color-dark;
            display: flex;
            align-items: center;
            @include transition;


            &:hover {
              color: $color-blue;
            }
          }
        }
      }

      &:hover {
        & > a {
          .icon {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        .sub-menu {
          opacity: 1;
          pointer-events: all;
          transform: translateY(0);
        }
      }

    }
  }

  .nav-toggle {
    display: none;
  }

  &__logo {
    img {
      max-height: 72px;
      display: block;
      width: auto;
      object-fit: contain;
    }
  }

  @media (max-width: 991px) {
    .menu {
      display: none;
    }
    .nav-toggle {
      display: block;
      width: 56px;
      cursor: pointer;
      padding: 8px;
      margin-right: -8px;

      span {
        display: block;
        margin: 10px 0;
        height: 2px;
        width: 40px;
        background-color: $color-white;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  @media (max-width: 767px) {
    &__logo {
      img {
        max-height: 50px;
      }
    }
  }
}


.nav-side {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 450px;
  background: linear-gradient(214.69deg, #72D2FE -2.73%, #45A6DC 55.53%);
  box-shadow: -10px 0px 44px rgba(0, 0, 0, 0.15);
  z-index: 150;
  max-height: $max-vh;
  pointer-events: none;
  transform: translateX(105%);
  max-width: 100%;
  @include transition;
  &.active {
    opacity: 1;
    transform: translateX(0);
    pointer-events: all;
  }
  .nav-close {
    right: 30px;
    top: 30px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
  }
  &__scroller {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding-top: 170px;
    padding-bottom: 125px;
  }
  .menu {
    & > li {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 45px;

      &.parent {
        & > a {
          margin-left: 20px;
        }
      }

      & > a {
        font-family: $font-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $color-white;
        padding: 10px;
        display: flex;
        align-items: center;

        .icon {
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include transition;

          &.active {
            transform: rotate(180deg);
          }

          svg {
            height: 8px;
            width: auto;
            object-fit: contain;
            margin-left: 5px;
          }
        }
      }
    }

    .sub-menu {

      width: 100%;
      padding-top: 30px;
      display: none;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          font-family: $font-primary;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 1.3;
          padding: 10px;
          text-align: center;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #D4F1FA;
        }
      }
    }

  }

  @media (max-width: 465px) {
    .nav-close {
      top: 16px;
      right: 16px;
      svg {
        height: 22px;
        width: auto;
        display: block;
        object-fit: contain;
      }
    }
    &__scroller{
      padding-top: 18vh;
    }
    .menu{
      &>li{
        margin-bottom: 36px;
      }
    }
  }
}