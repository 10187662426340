/* Old Browser */
.browsers__placeholder {
  font-family: "Helvetica", sans-serif;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  min-width: 100vw;
  background-image: none !important;
  text-align: center;
  background-color: black !important;
  font-size: 20px;
  color: #fff;
  z-index: 100000000000000000000000000000000000;
  line-height: 1.6;

  p {
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 1.6;
    font-family: inherit;
  }

  a {
    color: red;
  }
}