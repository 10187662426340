.wait{
  cursor: wait;
}

// Site Wrapper
.template-wrap {
  position: relative;
  width: 100%;
  max-width: $max-w;
  overflow-x: hidden;
}

// Lines for Sections
.lines-container {
  pointer-events: none;
  position: absolute !important;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;

  &__relative {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  .line {
    position: relative;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 0.5px;
  }

  &.white-lines {
    .line {
      background-color: rgba(255, 255, 255, .1);
    }
  }

  &.dark-lines {
    .line {
      background-color: rgba(#B4C5D7, .2);
    }
  }


  @media (max-width: 575px) {
    .line {
      &:nth-child(6) {
        display: none;
      }

      &:nth-child(7) {
        display: none;
      }
    }
  }
}

// Cookie Banner
.cookie-banner {
  filter: drop-shadow(7px 14px 44px rgba(0, 0, 0, 0.25));
  position: fixed;
  width: 400px;
  height: 250px;
  bottom: 50px;
  right: 50px;
  z-index: 250;
  background-color: $color-white;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
  @include transition;

  &.active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }

  .cookie-img {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(26%) translateY(-24%);
    pointer-events: none;
  }

  &__wrap {
    padding-left: 35px;
    padding-top: 30px;
    padding-bottom: 25px;
    padding-right: 20px;
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  p {
    max-width: 270px;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.4;
    letter-spacing: 0.05em;
    color: #7C91A6;
  }

  .btn-accept-cookie {
    margin-top: 20px;
  }

  @media (max-width: 991px) {
    right: 30px;
  }
  @media (max-width: 480px) {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    p {
      max-width: 100%;
      width: 100%;
      display: block;
    }
    .cookie-img {
      max-height: 150px;
    }
    &__wrap {
      padding-left: 35px;
      padding-right: 35px;
      padding-top: 85px;
      padding-bottom: 30px;
    }
    .btn-accept-cookie {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 33px;
    }
  }
}

// Consultation
.consultation {
  background-color: #EAF6FF;
  border: 1px solid #45A6DC;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 35px;
  padding-right: 35px;
  @media(max-width: 767px) {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
    .link {
      &__caption {
        text-decoration: underline;
      }

      &__inner {
        &:after {
          display: none;
        }
      }

      &__icon {
        display: none;
      }
    }
  }
}