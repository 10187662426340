.page-home {
  .client-group img {
    filter: grayscale(1);
  }

  // Hero Header Section
  .hero-section {
    width: 100%;
    position: relative;
    height: 1100px;
    z-index: 10;
    padding-top: calc(90px + 75px + 50px);

    // Background
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      z-index: 1;
    }

    // Content
    .container {
      position: relative;
      z-index: 10;
    }

    .heading {
      max-width: 880px;
    }

    .sub-heading {
      margin-bottom: 20px;
    }

    // Slider
    .slider-about {
      padding: 30px 0;
      margin-top: 45px;

      .swiper-slide {
        position: relative;
        width: 250px;
        height: auto;
        border: 0.5px solid rgba(255, 255, 255, 0.5);
        border-top: 0;
        margin-right: 40px;
        padding-left: 30px;
        padding-bottom: 20px;
        padding-right: 7px;
        padding-top: 50px;

        &:nth-child(1) {
          .notice {
            position: absolute;
            left: 0;
            top: 50%;
            font-family: $font-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: $color-white;
            transform: translateY(-50%) translateX(-100%)
              rotate(-90deg);
          }
        }

        .number {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          font-family: $font-primary;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 1.5;
          text-align: center;
          color: $color-white;
          transform: translateY(-50%);

          span {
            padding-left: 55px;
          }

          &:before {
            content: "";
            width: 40px;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.5);
            display: block;
            position: absolute;
            left: 0;
          }

          &:after {
            content: "";
            width: 155px;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.5);
            display: block;
            position: absolute;
            right: 0;
          }
        }

        .about-item {
          &__content {
            font-family: $font-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 1.4;
            color: $color-white;
          }
        }
      }
    }

    // Scroll Circle
    .scroll-circle {
      width: 250px;
      height: 250px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      border-radius: 100%;
      transform: translateX(-50%) translateY(50%);
      position: absolute;
      bottom: 0;
      left: 50%;

      &__relative {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .circle-half {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 15px;

          svg {
            height: 16px;
            width: auto;
            display: block;
            object-fit: contain;
          }
        }

        .section-label {
          text-align: center;
          font-family: $font-primary;
          font-style: normal;
          font-weight: 500;
          font-size: 11px;
          line-height: 1.82;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: $color-white;
        }
      }
    }

    @media (max-width: 991px) {
      height: auto;
      padding-top: calc(60px + 75px + 50px);
      padding-bottom: 240px;
    }
    @media (max-width: 575px) {
      .sub-heading {
        max-width: 320px;
      }
    }
  }

  // Services Section
  .services-grid {
    max-width: 2440px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .service-item {
      width: 50%;
      position: relative;

      .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        z-index: 1;
      }

      &:nth-child(odd) {
        display: flex;
        justify-content: flex-end;
      }

      &:nth-child(even) {
        display: flex;
        justify-content: flex-start;
      }

      &__small {
        .service-item__inner {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .service-item__description {
            max-width: 60%;
            margin-bottom: 0;
          }
        }
      }

      // Inner Block
      &__inner {
        width: 100%;
        max-width: 720px;
        position: relative;
        z-index: 10;
        padding-left: 90px;
        padding-right: 75px;
        padding-bottom: 75px;
        padding-top: 80px;
        color: $color-white;

        .link {
          position: absolute;
          bottom: 75px;
          right: 75px;
        }
      }

      &__title {
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      &__description {
        margin-bottom: 35px;
      }

      &__content {
        ul {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          li {
            font-family: $font-primary;
            font-style: normal;
            font-weight: 500;
            line-height: 1.85;
            color: $color-white;
            position: relative;

            &:before {
              display: inline-block;
              content: "•";
              font-size: inherit;
              color: inherit;
              margin-right: 10px;
            }
          }
        }
      }
    }

    @media (max-width: 1199px) {
      .service-item {
        &__inner {
          .link {
            margin-top: 70px;
            position: static;
          }
        }
      }
    }
    @media (max-width: 1024px) {
      .service-item {
        &__inner {
          padding-top: 80px;
          padding-bottom: 60px;
          padding-left: 40px;
          padding-right: 20px;
        }

        &__description {
          font-size: 14px;
        }

        &__content {
          font-size: 16px;
        }

        // Small
        &__small {
          .service-item__inner {
            .service-item__description {
              max-width: 100%;
              font-size: 18px;
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      .service-item {
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center;

        &__inner {
          padding-left: 40px;
          padding-right: 40px;

          .link {
            position: relative;
            left: 50%;
            right: auto;
            bottom: auto;
            transform: translateX(-50%);
          }
        }

        &__description {
          display: none;
        }

        &__title {
          text-align: center;
          margin-bottom: 40px;
        }

        &__content {
          font-size: 14px;
          max-width: 320px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    @media (max-width: 575px) {
      .service-item {
        &__inner {
          padding-left: 15px;
          padding-right: 15px;
        }

        &__small {
          .service-item__inner {
            .service-item__description {
              padding-top: 60px;
              display: block;
              font-size: 14px;
              line-height: 2;
            }
          }
        }
      }
    }
  }

  // About Section
  .section-about {
    background-color: $color-white;

    .bg {
      background: linear-gradient(
        214.69deg,
        #72d2fe -2.73%,
        #45a6dc 55.53%
      );
      opacity: 0.1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    padding-top: 115px;
    padding-bottom: 125px;

    .heading-flex {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    .section-subtitle {
      max-width: 360px;
    }

    .link-bot {
      display: none;
    }

    @media (max-width: 991px) {
      padding-top: 100px;
      padding-bottom: 85px;
      .heading-flex {
        align-items: center;
      }
    }
    @media (max-width: 767px) {
      padding-top: 80px;
      .heading-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .link {
          display: none;
        }
      }
      .section-title {
        text-align: center;
      }
      .section-subtitle {
        text-align: center;
        max-width: 290px;
        margin-left: auto;
        margin-right: auto;
      }
      .link-bot {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 50px;
      }
    }
  }

  // Clients Section
  .section-clients {
    padding: 160px 0;

    .clients-block {
      margin-left: auto;
      margin-right: auto;
      max-width: 720px;
      margin-top: 65px;

      .link {
        margin-top: 20px;
      }
    }

    .client-group {
      width: 100%;
      margin-bottom: 60px;

      &__title {
        line-height: 1;
        display: block;
        padding-bottom: 10px;
        border-bottom: 1px solid #000;
        text-transform: uppercase;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        letter-spacing: 0.05em;
        color: $color-black;
      }

      &__row {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;

        img {
          max-height: 115px;
          max-width: calc(100% / 3 - 40px);
          object-fit: contain;
          display: block;
        }
      }
    }

    @media (max-width: 991px) {
      padding: 80px 0;
    }
    @media (max-width: 767px) {
      .clients-block {
        margin-top: 55px;
        width: 100%;
        display: block;

        .link {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 20px;
        }
      }
      .section-title {
        text-align: center;
        display: block;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
      .client-group {
        &__row {
          margin-left: -15px;
          margin-right: -15px;
          padding-left: 15px;
          padding-right: 15px;
          overflow-x: auto;
          width: 100%;
          -webkit-overflow-scrolling: touch;

          img {
            width: auto;
            max-width: unset;
            max-height: 50px;
            margin-right: 70px;
          }
        }
      }
    }
  }

  // References Section
  .section-refs {
    padding-top: 60px;
    padding-bottom: 140px;
    @media (max-width: 767px) {
      padding-top: 20px;
      padding-bottom: 100px;
      .section-title {
        text-align: center;
      }
      .section-subtitle {
        text-align: center;
      }
    }
  }

  // Services Section
  .services-section {
    padding: 140px 0;

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      z-index: 1;
      background: linear-gradient(
        214.69deg,
        #72d2fe -2.73%,
        #45a6dc 55.53%
      );
      opacity: 0.08;
    }

    .section-header {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .slider-arrows {
      display: flex;
      align-items: center;

      &__inner {
        position: relative;
        width: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .prev,
      .next {
        height: 25px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 5px;

        .arrow {
          position: relative;

          &:after,
          &:before {
            @include transition;
          }

          span {
            @include transition;
          }
        }

        // Disabled
        &.swiper-button-disabled {
          pointer-events: none;

          .arrow {
            &:after,
            &:before {
              background-color: #b4c5d7;
            }

            span {
              background-color: #b4c5d7;
            }
          }
        }

        &:hover {
          .arrow {
            span {
              width: 52px;
            }
          }
        }
      }

      .prev {
        position: absolute;
        margin-right: 12px;
        right: 50%;

        .arrow {
          span {
            display: block;
            height: 2px;
            background-color: $color-dark;
            width: 34px;
            border-radius: 10px;
          }

          &:before {
            display: block;
            content: "";
            width: 2px;
            height: 8px;
            background-color: $color-dark;
            border-radius: 10px;
            position: absolute;
            left: 2px;
            bottom: -1px;
            transform: rotate(50deg);
          }

          &:after {
            display: block;
            content: "";
            width: 2px;
            height: 8px;
            background-color: $color-dark;
            border-radius: 10px;
            position: absolute;
            left: 2px;
            top: -1px;
            transform: rotate(-50deg);
          }
        }
      }

      .next {
        position: absolute;
        left: 50%;

        .arrow {
          span {
            display: block;
            height: 2px;
            background-color: $color-dark;
            width: 34px;
            border-radius: 10px;
          }

          &:before {
            display: block;
            content: "";
            width: 2px;
            height: 8px;
            background-color: $color-dark;
            border-radius: 10px;
            position: absolute;
            right: 2px;
            bottom: -1px;
            transform: rotate(-50deg);
          }

          &:after {
            display: block;
            content: "";
            width: 2px;
            height: 8px;
            background-color: $color-dark;
            border-radius: 10px;
            position: absolute;
            right: 2px;
            top: -1px;
            transform: rotate(50deg);
          }
        }
      }
    }

    .slider-services {
      margin-top: 40px;
      margin-bottom: 50px;

      .swiper-slide {
        width: 360px;
        display: flex;
        height: auto;
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }

        .service-item {
          width: 100%;
          background-color: $color-white;
          padding-top: 40px;
          padding-left: 50px;
          padding-right: 30px;
          padding-bottom: 40px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &__icon {
            position: relative;
            width: 70px;
            height: 70px;
            border-radius: 8px;
            margin-bottom: 30px;

            img,
            svg {
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateX(10px) translateY(-50%);
              max-height: 50px;
              width: auto;
              display: block;
              object-fit: contain;
            }
          }

          &__title {
            margin-bottom: 12px;
            font-family: $font-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 1.35;
            letter-spacing: 0.05em;
            color: $color-dark;
          }

          &__description {
            font-family: $font-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.7;
            color: rgba($color-dark, 0.75);
          }

          &__permalink {
            margin-top: 35px;
            display: inline-flex;
            align-items: center;
            font-family: $font-primary;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 1.35;
            color: $color-blue;
            @include transition;
          }
        }
      }
    }

    @media (max-width: 767px) {
      padding-top: 80px;
      padding-bottom: 80px;
      .section-header {
        justify-content: center;
        align-items: center;

        .section-title {
          text-align: center;
        }

        .slider-arrows {
          display: none;
        }
      }
      .slider-services {
        margin-top: 40px;
        margin-bottom: 40px;

        .swiper-slide {
          width: 270px;
          margin-right: 15px;

          .service-item {
            padding-top: 40px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 40px;
          }
        }
      }
      .link {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.team-slider-about {
  margin-top: 75px;
  padding: 10px 0;

  .swiper-slide {
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 40px;

    .avatar {
      min-width: 360px;
      width: 360px;
      display: flex;
      align-items: center;

      img {
        border-radius: 100%;
        overflow: hidden;
      }
    }

    .info {
      padding-left: 90px;

      .position {
        margin-top: 16px;
        margin-bottom: 18px;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.56;
        color: $color-blue;
      }

      .description {
        max-width: 540px;
      }
    }
  }

  .slider-dots {
    margin-top: 65px;
    margin-left: calc(360px + 90px);
    display: flex;
    flex-wrap: wrap;
    padding-left: 6px;

    .swiper-pagination-bullet {
      background-color: transparent;
      opacity: 1;
      margin: 0 6px;
      width: 20px;
      height: 15px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition;

      &:after {
        width: 100%;
        height: 2px;
        background-color: #b4c5d7;
        border-radius: 0;
        @include transition;
        display: block;
        content: "";
      }

      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.swiper-pagination-bullet-active,
      &:hover {
        &:after {
          background-color: $color-blue;
        }
      }

      &.swiper-pagination-bullet-active {
        width: 30px;
      }
    }
  }
}

@media (max-width: 991px) {
  .team-slider-about {
    .swiper-slide {
      align-items: flex-start;

      .avatar {
        width: 240px;
        min-width: 240px;
      }

      .info {
        padding-left: 43px;

        .description {
          max-width: 100%;
        }
      }
    }

    .slider-dots {
      margin-left: calc(240px + 43px);
    }
  }
}

@media (max-width: 767px) {
  .team-slider-about {
    margin-top: 40px;

    .swiper-slide {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-right: 40px;

      .avatar {
        width: 100%;
        min-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          border-radius: 100%;
          overflow: hidden;
          max-width: 240px;
        }
      }

      .info {
        padding: 0;
        padding-top: 15px;

        .name {
          text-align: center;
        }

        .position {
          text-align: center;
          margin-top: 8px;
          margin-bottom: 10px;
        }

        .description {
          text-align: left;
          font-size: 18px;
        }
      }
    }

    .slider-dots {
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
    }
  }
}

// References Slider
.refs-slider {
  overflow: unset;
  margin-top: 100px;

  .swiper-slide {
    display: flex;
    justify-content: center;

    .ref-item {
      position: relative;
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;

      .info {
        .message {
          font-family: $font-primary;
          font-style: normal;
          font-weight: 500;
          line-height: 1.35;
          color: $color-dark;
          margin-bottom: 20px;
          font-size: 32px;
          max-width: 85%;
        }

        .position {
          color: $color-blue;
        }
      }

      .avatar {
        z-index: -1;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%)
          translateX(calc(50% + 140px));
        width: 400px;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 100%;
          overflow: hidden;
        }
      }
    }
  }

  .slider-arrows {
    width: 100%;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 95px;
    display: flex;
    align-items: center;
    padding-left: 2px;

    &__inner {
      position: relative;
      width: 85px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .prev,
    .next {
      height: 25px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 5px;

      .arrow {
        position: relative;

        &:before,
        &:after {
          @include transition;
        }

        span {
          @include transition;
        }
      }

      // Disabled
      &.swiper-button-disabled {
        pointer-events: none;

        .arrow {
          &:after,
          &:before {
            background-color: #b4c5d7;
          }

          span {
            background-color: #b4c5d7;
          }
        }
      }

      &:hover {
        .arrow {
          span {
            width: 52px;
          }
        }
      }
    }

    .prev {
      position: absolute;
      margin-right: 12px;
      right: 50%;

      .arrow {
        span {
          display: block;
          height: 2px;
          background-color: #006ab2;
          width: 34px;
          border-radius: 10px;
        }

        &:before {
          display: block;
          content: "";
          width: 2px;
          height: 8px;
          background-color: #006ab2;
          border-radius: 10px;
          position: absolute;
          left: 2px;
          bottom: -1px;
          transform: rotate(50deg);
        }

        &:after {
          display: block;
          content: "";
          width: 2px;
          height: 8px;
          background-color: #006ab2;
          border-radius: 10px;
          position: absolute;
          left: 2px;
          top: -1px;
          transform: rotate(-50deg);
        }
      }
    }

    .next {
      position: absolute;
      left: 50%;

      .arrow {
        span {
          display: block;
          height: 2px;
          background-color: #006ab2;
          width: 34px;
          border-radius: 10px;
        }

        &:before {
          display: block;
          content: "";
          width: 2px;
          height: 8px;
          background-color: #006ab2;
          border-radius: 10px;
          position: absolute;
          right: 2px;
          bottom: -1px;
          transform: rotate(-50deg);
        }

        &:after {
          display: block;
          content: "";
          width: 2px;
          height: 8px;
          background-color: #006ab2;
          border-radius: 10px;
          position: absolute;
          right: 2px;
          top: -1px;
          transform: rotate(50deg);
        }
      }
    }
  }

  @media (max-width: 1600px) {
    .swiper-slide {
      .ref-item {
        width: 100%;

        .info {
          max-width: 565px;

          .message {
            font-size: 30px;
          }
        }

        .avatar {
          width: 315px;
          height: 315px;
          transform: translateY(-50%) translateX(50%);
        }
      }
    }

    .slider-arrows {
      margin-top: 70px;
    }
  }
  @media (max-width: 991px) {
    .swiper-slide {
      .ref-item {
        width: 100%;

        .info {
          max-width: 440px;

          .message {
            max-width: 100%;
            font-size: 28px;
          }
        }

        .avatar {
          width: 290px;
          height: 290px;
          transform: translateY(-50%) translateX(0);
        }
      }
    }

    .slider-arrows {
      margin-top: 70px;
    }
  }

  @media (max-width: 767px) {
    margin-top: 30px;

    .swiper-slide {
      .ref-item {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

        .info {
          width: 100%;
          max-width: 480px;
          margin-left: auto;
          margin-right: auto;

          .message {
            font-size: 24px;
            text-align: center;
          }

          .position {
            text-align: center;
          }
        }

        .avatar {
          width: 240px;
          height: 240px;
          transform: none;
          position: static;
          margin-bottom: 25px;
        }
      }
    }

    .slider-arrows {
      margin-top: 30px;
      justify-content: center;
    }
  }
}
