// Page Servies
.page-services {
  // Benefits Section
  .section-benefits {
    background: linear-gradient(
      143.87deg,
      #ffffff 21.1%,
      #eff9ff 74.08%
    );
    padding-top: 160px;
    padding-bottom: 140px;

    // Benefits Grid
    .grid-benefits {
      margin-top: 90px;
      display: flex;
      flex-wrap: wrap;

      .grid-item {
        cursor: initial;
        width: 50%;
        position: relative;

        &__front {
          @include transition;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 10;

          &-inner {
            width: 300px;

            .icon {
              margin-bottom: 35px;

              img,
              svg {
                height: 110px;
                width: auto;
                display: block;
                object-fit: contain;
              }
            }

            .caption {
              font-family: $font-primary;
              font-style: normal;
              font-weight: 500;
              font-size: 22px;
              line-height: 1.3;
              color: $color-blue;
            }
          }
        }

        &__back {
          position: relative;
          z-index: 15;
          transform: translateY(15px);
          @include transition;
          opacity: 0;
          pointer-events: none;
          min-height: 310px;
          background: linear-gradient(
            214.69deg,
            #72d2fe -2.73%,
            #45a6dc 55.53%
          );
          mix-blend-mode: normal;
          display: flex;
          justify-content: center;
          align-items: center;

          .content {
            padding-top: 70px;
            padding-bottom: 70px;
            padding-left: 75px;
            padding-right: 60px;
            color: $color-white;
          }
        }

        &:hover {
          .grid-item__back {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0);
          }

          .grid-item__front {
            pointer-events: none;
          }
        }
      }
    }

    // Cta Block
    .cta-block {
      margin-top: 140px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      &__left {
        max-width: 540px;
        margin-right: 35px;
      }
    }

    .consultation {
      margin-top: 100px;
    }

    @media (max-width: 991px) {
      // Benefits Grid
      .grid-benefits {
        margin-top: 60px;

        .grid-item {
          &__back {
            .content {
              padding-top: 60px;
              padding-bottom: 55px;
              padding-left: 35px;
              padding-right: 30px;
            }
          }
        }
      }

      // Cta Block
      .cta-block {
        align-items: center;

        &__left {
          max-width: 430px;
        }
      }

      // Consultation
      .consultation {
        margin-top: 70px;
      }
    }
    @media (max-width: 767px) {
      padding-top: 60px;
      padding-bottom: 90px;
      .section-title {
        text-align: center;
      }

      // Benefits Grid
      .grid-benefits {
        margin-top: 50px;
        flex-wrap: wrap;
        flex-direction: column;

        .grid-item {
          width: 100%;
          max-width: 480px;
          margin-left: auto;
          margin-right: auto;

          &__front {
            &-inner {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .icon {
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .caption {
                text-align: center;
              }
            }

            .caption {
              text-align: center;
            }
          }

          &__back {
            min-height: 280px;

            .content {
              padding-left: 35px;
              padding-right: 35px;
              padding-top: 60px;
              padding-bottom: 72px;
              line-height: 1.8;
            }
          }
        }
      }

      // Cta Block
      .cta-block {
        margin-top: 60px;
        flex-wrap: wrap;

        &__left {
          max-width: 430px;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }

        &__right {
          margin-top: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }

      // Consultation
      .consultation {
        margin-top: 60px;
      }
    }
  }

  // It Support
  .section-it-support {
    padding-top: 140px;
    padding-bottom: 150px;
    background: linear-gradient(
      143.87deg,
      #ffffff 21.1%,
      #eff9ff 78.9%
    );

    .section-title {
      text-align: center;
      margin-bottom: 80px;
    }

    .benefits-slider-wrap {
      margin-top: 100px;
      margin-bottom: 40px;
    }

    @media (max-width: 991px) {
      padding-top: 80px;
      padding-bottom: 100px;
      .benefits-slider-wrap {
        margin-top: 60px;
      }
    }
    @media (max-width: 767px) {
      .section-title {
        margin-bottom: 20px;
      }
      .benefits-slider-wrap {
        margin-top: 30px;
      }
    }
  }

  // Database
  .section-db {
    padding-top: 100px;
    padding-bottom: 160px;

    .section-title {
      text-align: center;
      margin-bottom: 20px;
    }

    .section-subtitle {
      text-align: center;

      p {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .chess-grid {
      margin-top: 80px;
      margin-bottom: 140px;
    }

    @media (max-width: 991px) {
      padding-top: 80px;
      padding-bottom: 100px;
      .section-subtitle {
        br {
          display: none;
        }
      }

      .chess-grid {
        margin-bottom: 80px;
      }
    }
  }

  // Progress Section
  .section-progress {
    background: linear-gradient(
      143.87deg,
      #ffffff 21.1%,
      #eff9ff 78.9%
    );
    padding-top: 160px;
    padding-bottom: 140px;

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .section-title {
        margin-right: 90px;
      }
    }

    // Slider Progress
    .progress-slider-wrap {
      margin-top: 40px;

      .slider-progress {
        padding-top: 75px;
        padding-bottom: 60px;

        .swiper-slide {
          width: 330px;
          margin-right: 30px;

          .progress-item {
            position: relative;

            .number {
              transform: translateY(-50px);
              font-family: $font-primary;
              font-style: normal;
              font-weight: bold;
              font-size: 100px;
              line-height: 0.9;
              color: #e5f1fb;
              position: absolute;
              top: 0;
              z-index: 0;
            }

            .caption {
              min-height: 85px;
              position: relative;
              z-index: 10;
              margin-bottom: 30px;
              max-width: 290px;
            }

            .description {
              position: relative;
              z-index: 10;
              font-family: $font-primary;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 1.7;
              color: $color-grey;
            }
          }
        }
      }

      .slider-arrows {
        padding: 15px 0;
        display: flex;
        align-items: center;

        &__inner {
          position: relative;
          width: 85px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .prev,
        .next {
          height: 25px;
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-right: 5px;

          .arrow {
            position: relative;

            &:after,
            &:before {
              @include transition;
            }

            span {
              @include transition;
            }
          }

          // Disabled
          &.swiper-button-disabled {
            pointer-events: none;

            .arrow {
              &:after,
              &:before {
                background-color: #b4c5d7;
              }

              span {
                background-color: #b4c5d7;
              }
            }
          }

          &:hover {
            .arrow {
              span {
                width: 52px;
              }
            }
          }
        }

        .prev {
          position: absolute;
          margin-right: 12px;
          right: 50%;

          .arrow {
            span {
              display: block;
              height: 2px;
              background-color: $color-dark;
              width: 34px;
              border-radius: 10px;
            }

            &:before {
              display: block;
              content: "";
              width: 2px;
              height: 8px;
              background-color: $color-dark;
              border-radius: 10px;
              position: absolute;
              left: 2px;
              bottom: -1px;
              transform: rotate(50deg);
            }

            &:after {
              display: block;
              content: "";
              width: 2px;
              height: 8px;
              background-color: $color-dark;
              border-radius: 10px;
              position: absolute;
              left: 2px;
              top: -1px;
              transform: rotate(-50deg);
            }
          }
        }

        .next {
          position: absolute;
          left: 50%;

          .arrow {
            span {
              display: block;
              height: 2px;
              background-color: $color-dark;
              width: 34px;
              border-radius: 10px;
            }

            &:before {
              display: block;
              content: "";
              width: 2px;
              height: 8px;
              background-color: $color-dark;
              border-radius: 10px;
              position: absolute;
              right: 2px;
              bottom: -1px;
              transform: rotate(-50deg);
            }

            &:after {
              display: block;
              content: "";
              width: 2px;
              height: 8px;
              background-color: $color-dark;
              border-radius: 10px;
              position: absolute;
              right: 2px;
              top: -1px;
              transform: rotate(50deg);
            }
          }
        }
      }
    }

    // Notice
    .notice {
      margin-top: 60px;
      max-width: 630px;
      font-family: $font-primary;
      font-style: normal;
      font-weight: 500;
      color: $color-dark;
    }

    @media (max-width: 991px) {
      padding-bottom: 120px;
      padding-top: 80px;
      .progress-slider-wrap {
        .slider-progress {
          padding-bottom: 0;
        }
        .slider-arrows {
          display: none;
        }
      }
    }
    @media (max-width: 767px) {
      .section-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .section-title {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
        .section-subtitle {
          text-align: center;
        }
      }
      .progress-slider-wrap {
        .slider-progress {
          padding-bottom: 0;
          .swiper-slide {
            max-width: 250px;
            .progress-item {
              .caption {
                min-height: 80px;
                font-size: 18px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}

// Chess Grid
.chess-grid {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  .grid-row {
    display: flex;
    flex-wrap: wrap;

    &:nth-child(even) {
      flex-direction: row-reverse;

      .grid-item__content {
        justify-content: flex-start;
        padding-left: 90px;
      }
    }

    &:nth-child(odd) {
      .grid-item__content {
        justify-content: flex-end;
        padding-right: 9.5%;
      }
    }
  }

  .grid-item {
    width: 50%;

    &__content {
      padding: 60px 20px;
      display: flex;
      align-items: center;

      &-inner {
        max-width: 400px;

        p {
          margin-bottom: 1em;

          &:last-child {
            margin-bottom: 0;
          }
        }

        strong,
        a {
          color: $color-blue;
          font-weight: 500;
        }

        ul {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          li {
            position: relative;

            &:before {
              display: inline-block;
              content: "•";
              font-size: inherit;
              color: inherit;
              margin-right: 10px;
            }
          }
        }
      }
    }

    &__image {
      max-height: 450px;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:after {
        padding-bottom: 62.5%;
        display: block;
        content: "";
      }
    }
  }

  @media (max-width: 991px) {
    .grid-item {
      &__content {
        &-inner {
          max-width: 300px;
        }
      }
    }
    .grid-row {
      &:nth-child(even) {
        .grid-item__content {
          justify-content: center;
          padding-left: 40px;
          padding-right: 40px;
        }
      }

      &:nth-child(odd) {
        .grid-item__content {
          justify-content: center;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }
  }
  @media (max-width: 660px) {
    .grid-row {
      flex-direction: column;
    }
    .grid-item {
      width: 100%;

      &__content {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

// Benefits Slider
.benefits-slider-wrap {
  .arrows-wrap {
    padding-bottom: 20px;
    position: relative;
    z-index: 35;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .slider-arrows {
      display: flex;
      align-items: center;

      &__inner {
        position: relative;
        width: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .prev,
      .next {
        height: 25px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 5px;

        .arrow {
          position: relative;

          &:after,
          &:before {
            @include transition;
          }

          span {
            @include transition;
          }
        }

        // Disabled
        &.swiper-button-disabled {
          pointer-events: none;

          .arrow {
            &:after,
            &:before {
              background-color: #b4c5d7;
            }

            span {
              background-color: #b4c5d7;
            }
          }
        }

        &:hover {
          .arrow {
            span {
              width: 52px;
            }
          }
        }
      }

      .prev {
        position: absolute;
        margin-right: 12px;
        right: 50%;

        .arrow {
          span {
            display: block;
            height: 2px;
            background-color: $color-dark;
            width: 34px;
            border-radius: 10px;
          }

          &:before {
            display: block;
            content: "";
            width: 2px;
            height: 8px;
            background-color: $color-dark;
            border-radius: 10px;
            position: absolute;
            left: 2px;
            bottom: -1px;
            transform: rotate(50deg);
          }

          &:after {
            display: block;
            content: "";
            width: 2px;
            height: 8px;
            background-color: $color-dark;
            border-radius: 10px;
            position: absolute;
            left: 2px;
            top: -1px;
            transform: rotate(-50deg);
          }
        }
      }

      .next {
        position: absolute;
        left: 50%;

        .arrow {
          span {
            display: block;
            height: 2px;
            background-color: $color-dark;
            width: 34px;
            border-radius: 10px;
          }

          &:before {
            display: block;
            content: "";
            width: 2px;
            height: 8px;
            background-color: $color-dark;
            border-radius: 10px;
            position: absolute;
            right: 2px;
            bottom: -1px;
            transform: rotate(-50deg);
          }

          &:after {
            display: block;
            content: "";
            width: 2px;
            height: 8px;
            background-color: $color-dark;
            border-radius: 10px;
            position: absolute;
            right: 2px;
            top: -1px;
            transform: rotate(50deg);
          }
        }
      }
    }
  }

  .slider-benefits {
    padding-top: 40px;
    padding-bottom: 60px;

    .swiper-slide {
      width: 280px;
      height: 190px;
      background: #ffffff;
      box-shadow: 0px 24px 44px rgba(0, 106, 178, 0.05);
      border: 1px solid #c7e4f5;
      margin-right: 20px;
      @include transition;

      &:hover {
        transform: translateY(-10px);
      }

      .benefit-item {
        padding-top: 32px;
        padding-left: 45px;
        padding-right: 45px;

        .number {
          margin-bottom: 20px;
          position: relative;
          width: 35px;
          height: 35px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          overflow: hidden;

          &:after {
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            background: linear-gradient(
              214.69deg,
              #72d2fe -2.73%,
              #45a6dc 55.53%
            );
            opacity: 0.2;
            position: absolute;
            left: 0;
            right: 0;
            top: 0%;
            bottom: 0;
          }

          font-family: $font-primary;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 1;
          color: $color-blue;
        }

        .benefit {
          font-size: 18px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .arrows-wrap {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .slider-benefits {
      .swiper-slide {
        width: 240px;
        height: auto;
        min-height: 190px;

        .benefit-item {
          padding-left: 35px;
          padding-right: 35px;
        }

        .benefit {
          font-size: 16px;
        }
      }
    }
  }
}

// Text Container
.text-content {
  .section-title {
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 30px !important;
  }

  .section-subtitle {
    text-align: center;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  .btn-wrap {
    margin-bottom: 60px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.divider {
  background-color: #cff0ff;
  margin: 0;
  display: block;
  width: 100%;
  height: 1px;
  border: none;
}
