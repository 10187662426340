.page-404{

  .page-hero-section{
    display: flex;
    align-items: center;
    .heading{
      margin-bottom: 20px;
    }
    .sub-heading{
      max-width: 640px;
    }
    .links-wrap{
      margin-top: 40px;
    }
  }

}