.page-policy {
  .page-hero-section {
    display: flex;
    align-items: center;

    .heading {
      margin-bottom: 20px;
    }

    .sub-heading {
      max-width: 640px;
    }

    .links-wrap {
      margin-top: 40px;
    }
    @media (max-width: 767px) {
      min-height: 300px;
    }
  }
  .section-policy {
    padding-top: 80px;
    padding-bottom: 20px;
    .policy-row {
      display: flex;
      justify-content: space-between;
      .col-content {
        max-width: 640px;
      }
      .sidebar {
        padding-left: 30px;
        width: calc(100% - 640px);
        display: flex;
        justify-content: center;
        .links {
          li {
            margin-bottom: 15px;
            a {
              @include transition;
            }
            &.current, &:hover {
              a {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    @media (max-width: 991px) {
      .policy-row {
        .col-content {
          max-width: 480px;
        }
        .sidebar {
          padding-left: 30px;
          width: calc(100% - 480px);
        }
      }
    }
    @media (max-width: 767px) {
      padding-top: 60px;
      .policy-row{
        flex-direction: column-reverse;
        flex-wrap: wrap;
        .col-content{
          max-width: 100%;
          padding-top: 30px;
        }
        .sidebar{
          width: 100%;
          padding-left: 0;
          .links{
            li{
              text-align: center;
              a{
                text-align: center;
              }
            }
          }
        }
      }
    }
  }




}