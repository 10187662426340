// Page References
.page-references {

  // Hero Header
  .page-hero-section {
    padding-top: 180px;
    padding-bottom: 180px;
    display: flex;
    align-items: center;

    .sub-heading {
      max-width: 630px;
      margin-bottom: 0;
      margin-top: 20px;
    }

    @media (max-width: 991px) {
      padding-bottom: 120px;
      min-height: unset;
      .heading {
        text-align: left;
      }
      .sub-heading {
        text-align: left;
      }
    }
  }

  // References
  .section-refs {
    background: linear-gradient(143.87deg, #FFFFFF 21.1%, #EFF9FF 74.08%);
    padding-top: 140px;
    padding-bottom: 140px;

    .link-wrap {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 991px) {
      padding-top: 130px;
      padding-bottom: 120px;
      .link-wrap {
        margin-top: 60px;
      }
    }
    @media (max-width: 767px) {
      .section-title {
        text-align: center;
      }
      .section-subtitle {
        text-align: center;
      }
      padding-top: 80px;
      padding-bottom: 90px;


      .link-wrap {
        .link {
          &__caption {
            text-decoration: underline;
          }

          &__inner {
            &:after {
              display: none;
            }
          }

          &__icon {
            display: none;
          }
        }
      }

    }
  }

  // Case Studies
  .section-case-studies {
    padding-top: 140px;
    padding-bottom: 230px;

    .section-headers {
      display: flex;
      justify-content: space-between;

      .section-title {
        margin-right: 90px;
      }

      .section-subtitle {
        max-width: 535px;
      }
    }

    .case-study-grid {
      margin-top: 60px;
      display: flex;
      flex-wrap: wrap;
      margin-left: -7.5px;
      margin-right: -7.5px;

      // Item
      .case-study-item {
        width: calc(100% / 3 - 15px);
        margin: 7.5px;
        position: relative;
        @include transition;
        &:after{
          display: block;
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          right: 0;
          background: linear-gradient(214.69deg, #72D2FE -2.73%, #45A6DC 55.53%);
          opacity: 0.85;
        }

        .bg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          z-index: 1;
        }

        .overlay {
          background: linear-gradient(214.69deg, #72D2FE -2.73%, #45A6DC 55.53%);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          z-index: 5;
          opacity: 0;
          @include transition;
        }

        &__inner {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          overflow: hidden;
          position: relative;
          z-index: 15;
          padding-top: 75px;
          padding-left: 50px;
          padding-right: 40px;
          padding-bottom: 30px;

          .content {
            color: $color-white;
            display: block;
            margin-bottom: 40px;
            font-size: 18px;
          }

          .link {
            @include transition;
            opacity: 0;
            transform: translateY(15px);
          }
        }

        &:hover {
          box-shadow: 0px 14px 54px rgba(0, 106, 178, 0.3);

          .overlay {
            opacity: 1;
          }

          .case-study-item__inner {
            .link {
              opacity: 1;
              transform: translateY(0);
            }
          }

        }
      }
    }

    .link-wrap {
      margin-top: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .case-study-description {
      margin-top: 80px;
      margin-left: auto;
      margin-right: auto;
      max-width: 720px;
      text-align: center;
    }

    .link-consultation {
      margin-top: 80px;
      display: flex;
      justify-content: center;
      align-content: center;
    }


    @media (max-width: 991px) {
      padding-top: 120px;
      padding-bottom: 160px;
      .section-headers {
        .section-subtitle {
          max-width: 340px;
        }
      }
      .case-study-grid {
        // Item
        .case-study-item {
          width: calc(100% / 2 - 15px);
        }
      }
    }

    @media (max-width: 767px) {
      padding-top: 80px;
      padding-bottom: 95px;
      .section-headers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .section-title {
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .case-study-grid {
        margin-top: 40px;
      }
      .case-study-description {
        margin-top: 40px;
      }
      .link-consultation {
        margin-top: 60px;

        .link {
          &__caption {
            text-decoration: underline;
          }

          &__inner {
            &:after {
              display: none;
            }
          }

          &__icon {
            display: none;
          }
        }
      }

    }


    @media (max-width: 565px) {
      .case-study-grid {
        // Item
        .case-study-item {
          width: calc(100% - 15px);

          &__inner {
            padding-left: 30px;
            padding-right: 18px;
            padding-bottom: 50px;
          }
        }
      }
    }


  }


}


.modal-scroller {
  width: 100%;
  height: $max-vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.case-study-modal {
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 150;
  position: fixed;
  display: block;
  .modal-wrap {
    margin-top: 90px;
    margin-bottom: 200px;
    position: relative;
    max-width: calc(100% - 80px);
    width: 1080px;
    background-color: $color-white;
    margin-left: auto;
    margin-right: auto;
  }
  .popup-header {
    height: 420px;
    width: 100%;
    position: relative;
    .close-modal {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .popup-body {
    padding-bottom: 200px;
    padding-top: 85px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: $color-white;
    .title {
      display: block;
      margin-bottom: 40px;
    }
    .content {
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
    }

    .content__footer{
      margin-top: 50px;
      .notice{
        margin-bottom: 40px;
      }
    }


  }

  @media (max-width: 1199px) {
    .popup-body{
      padding-left: 60px;
      padding-right: 60px;
      padding-bottom: 85px;
    }
  }
  @media (max-width: 767px) {
    .popup-header{
      height: 280px;
      .close-modal{
        right: 20px;
        top: 20px;
        svg{
          height: 20px;
          width: auto;
          display: block;
          object-fit: contain;
        }
      }
    }
    .modal-wrap{
      max-width: 100%;
      margin-top: 0;
      margin-bottom: 0;
    }

    .popup-body{
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 120px;
      .title{
        font-size: 26px;
      }
      .content__footer{
        .notice{
          font-size: 18px;
          text-align: center;
        }
        .link {
          &__caption {
            text-decoration: underline;
          }

          &__inner {
            &:after {
              display: none;
            }
          }

          &__icon {
            display: none;
          }
        }
      }
    }
  }
}