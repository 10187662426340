body {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.6;
  color: $color-dark;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.font-small {
  font-size: 14px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  line-height: 1.35;
  font-family: $font-primary;
  font-style: normal;
  font-weight: 500;
}

h1, .h1 {
  font-size: 50px;
  @media (max-width: 991px) {
    font-size: 46px;
  }
}

h2, .h2 {
  font-size: 44px;
  @media (max-width: 991px) {
    font-size: 40px;
  }
}

h3, .h3 {
  font-size: 40px;
  @media (max-width: 991px) {
    font-size: 36px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
  }
}

h4, .h4 {
  font-size: 34px;
  @media (max-width: 767px) {
    font-size: 26px;
  }
}

h5, .h5 {
  font-size: 24px;
}

h6, .h6 {
  font-size: 22px;
}

.link {
  cursor: pointer;
  @include transition;
  display: inline-flex;
  &__inner {
    display: flex;
    position: relative;
    &:after {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      @include transition;
    }
  }

  &__caption {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    @include transition;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px;

    svg {
      width: 10px;
      height: auto;
      display: block;

      path {
        @include transition;
      }

      object-fit: contain;
    }
  }

  // White
  &.link-white {
    .link__caption {
      color: $color-white;
    }
    .link__icon {
      svg {
        path {
          stroke: $color-white;
        }
      }
    }
    .link__inner{
      &:after{
        background-color:  $color-white;
      }
    }
    &:hover {
      opacity: .8;
    }
  }

  // Dark
  &.link-dark {
    .link__caption {
      color: $color-dark;
    }
    .link__icon {
      svg {
        path {
          stroke: $color-dark;
        }
      }
    }
    .link__inner{
      &:after{
        background-color: $color-dark;
      }
    }

    &:hover{
      .link__caption {
        color: $color-blue;
      }
      .link__icon {
        svg {
          path {
            stroke: $color-blue;
          }
        }
      }
      .link__inner{
        &:after{
          background-color: $color-blue;
        }
      }
    }
  }

  // Blue
  &.link-blue {
    .link__caption {
      color: $color-blue;
    }
    .link__icon {
      svg {
        path {
          stroke: $color-blue;
        }
      }
    }
    .link__inner{
      &:after{
        background-color:  $color-blue;
      }
    }
    &:hover {
      opacity: .8;
    }
  }

}

.section-title {
  margin-bottom: 8px;
  @media (max-width: 767px) {
    font-size: 25px;
  }
}

.post-content{
  strong {
    color: $color-blue;
    font-weight: 500;
  }
  p {
    margin-bottom: 2em;
    line-height: 1.7;
  }
  img{
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}