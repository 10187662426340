// Grid Gap
$grid-gap: 30px;

// Window Height 100vh including mobile browser toolbar
$max-vh: calc(var(--vh, 1vh) * 100);

// Maximum Width including scrollbar
$max-w: calc(100vw - var(--scroll-width));


$font-primary: 'HK Nova', sans-serif;
$font-secondary: 'Proxima Nova Semibold';


$color-white: #fff;
$color-dark: #2D2D2D;
$color-blue: #006AB2;
$color-grey: #7C91A6;
$color-black: #040C1E;




$color-1: #FFF7E3;
$color-2: #ECF9E9;
$color-3: #E3F1F5;
$color-4:#F5E3E3;


.bg-color-1{
  background-color: $color-1;
}
.bg-color-2{
  background-color: $color-2;
}
.bg-color-3{
  background-color: $color-3;
}
.bg-color-4{
  background-color: $color-4;
}