// Page References
.page-about {

  // Hero Header
  .page-hero-section {
    padding-top: 180px;
    padding-bottom: 180px;
    display: flex;
    align-items: center;

    .sub-heading {
      max-width: 600px;
      margin-bottom: 0;
      margin-top: 20px;
    }

    @media (max-width: 991px) {
      padding-bottom: 120px;
      min-height: unset;
      .heading {
        text-align: left;
      }
      .sub-heading {
        text-align: left;
      }
    }
  }

  // Section About
  .section-about {
    padding-top: 140px;
    padding-bottom: 120px;
    background: linear-gradient(143.87deg, #FFFFFF 21.1%, #EFF9FF 74.08%);

    .section-headers {
      display: flex;
      justify-content: space-between;

      .section-title {
        margin-right: 90px;

        span {
          color: $color-blue;
        }
      }

      .section-subtitle {
        max-width: 540px;
      }
    }

    .slider-subsection {
      margin-top: 140px;

      .heading-flex {
        justify-content: space-between;
        align-items: center;
        display: flex;

        .headers {
          max-width: 400px;
        }
      }

      .link-bot {
        display: none;
      }
    }

    @media (max-width: 991px) {
      padding-top: 80px;
      padding-bottom: 120px;
      .section-headers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .section-title {
          text-align: center;
          max-width: 560px;
          margin-left: auto;
          margin-right: auto;
        }

        .section-subtitle {
          max-width: 100%;
          margin-top: 30px;
        }
      }
      .slider-subsection {
        margin-top: 100px;
      }
    }
    @media (max-width: 767px) {
      padding-top: 60px;
      padding-bottom: 60px;
      .section-headers {
        .section-subtitle {
          margin-top: 20px;
        }
      }
      .slider-subsection {
        margin-top: 60px;

        .heading-flex {
          .link {
            display: none;
          }

          .headers {
            text-align: center;
            max-width: 100%;
            width: 100%;
          }
        }

        .link-bot {
          margin-top: 50px;
          display: inline-flex;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  // Job Section
  .section-job {
    background: linear-gradient(143.87deg, #FFFFFF 21.1%, #EFF9FF 74.08%);
    padding-top: 140px;
    padding-bottom: 180px;
    .job-block {
      margin-bottom: 80px;
    }
    .team-block {
      align-items: flex-start;
      margin-top: 70px;
    }

    .pre-footer-section {
      padding-top: 140px;
      .headings {
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .section-title{
          max-width: 510px;
        }
        .link{
          margin-bottom: 20px;
        }
      }
      .link-bot{
        display: none;
      }
      .content{
        max-width: 720px;
        p{
          margin-bottom: 1em;
        }
      }
      @media (max-width: 991px) {
        padding-top: 80px;
        .headings{
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
          .section-title{
            text-align: center;
            margin-left: auto;
            margin-right: auto;
          }
          .link{
            display: none;
          }
        }
        .content{
          margin-top: 40px;
          margin-bottom: 40px;
        }
        .link-bot{
          display: inline-flex;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    @media (max-width: 991px) {
      padding-top: 120px;
      padding-bottom: 120px;
      .job-block {
        margin-bottom: 40px;
      }
    }
    @media (max-width: 767px) {
      padding-top: 80px;
      padding-bottom: 100px;
    }
  }

}


// 2 Cols Block
.two-cols-block {
  display: flex;
  align-items: center;

  .col-left {
    width: 50%;
  }

  .col-right {
    width: 50%;

    &.col-content {
      padding-left: 90px;
    }
  }

  .col-content {
    .section-title {
      margin-bottom: 30px;
    }

    .description {
      p {
        margin-bottom: 1.5rem;
      }
    }
  }

  .acc-item {
    cursor: pointer;
    @include transition;
    border: 1px solid transparent;

    &__header {
      @include transition;
      padding-left: 50px;
      padding-right: 30px;
      padding-top: 25px;
      padding-bottom: 25px;
      display: flex;
      align-items: center;

      .number {
        @include transition;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: #7C91A6;
        margin-right: 15px;
      }

      .label {
        @include transition;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        color: #7C91A6;
      }

    }

    &__body {
      padding-left: 90px;
      font-size: 14px;
      padding-right: 30px;
      display: none;
      padding-bottom: 40px;
    }

    &.active {
      border: 1px solid #C7E4F4;
      background-color: $color-white;
      box-shadow: 0px 14px 54px rgba(0, 106, 178, 0.15);

      .acc-item__header {
        .number {
          color: $color-blue;
        }

        .label {
          color: $color-dark;
        }
      }

      .acc-item__body {
        height: auto;
      }
    }
  }

  @media (max-width: 991px) {
    flex-wrap: wrap;
    .col-left {
      width: 100%;
    }
    .col-right {
      width: 100%;

      &.col-content {
        padding-left: 0;
      }
    }
    .col-image {
      img {
        max-height: 400px;
        object-fit: cover;
        width: 100%;
        height: auto;
      }
    }
    .col-content {
      padding-top: 60px;

      .section-title {
        text-align: center;
      }

      .description {
      }
    }
    .acc-item {
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: 767px) {
    .acc-item {
      &__header {
        padding-top: 25px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 25px;

        .label {
          font-size: 18px;
        }
      }

      &__body {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
      }
    }
  }
}

// Testimonials Block
.testimonial-block {
  padding-top: 65px;
  padding-bottom: 85px;
  position: relative;

  .review-item {
    &__message {
      display: flex;

      .icon {
        margin-right: 40px;

        svg {
          height: 40px;
          width: auto;
          display: block;
          object-fit: contain;
        }
      }

      .content {
        max-width: 630px;
      }
    }

    &__author {
      margin-top: 40px;
      display: flex;
      align-items: center;

      .avatar {
        margin-right: 30px;

        img {
          overflow: hidden;
          width: 90px;
          height: 90px;
          border-radius: 100%;
          display: block;
          object-fit: contain;
        }
      }

      .work-time {
        color: $color-grey;
      }
    }
  }

  .map-wrap {
    top: 0;
    bottom: 0;
    position: absolute;
    width: 50%;
    left: 50%;
    pointer-events: none;

    .relative-map-wrap {
      width: 100%;
      position: relative;

      img {
        position: absolute;
        left: 0;
        max-width: unset;
      }
    }
  }

  @media (max-width: 991px) {
    .map-wrap {
      width: 100%;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .relative-map-wrap {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 100%;
          display: block;
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 20px;
    .review-item {
      &__message {
        flex-direction: column;
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;

        .icon {
          margin-bottom: 20px;
        }
      }

      &__author {
        .avatar {
          margin-right: 20px;

          img {
            width: 70px;
            height: 70px;
          }
        }
      }
    }
  }
  @media (max-width: 370px) {
    .review-item {
      &__message {
        .content {
          &__text {
            font-size: 18px;
          }
        }
      }
    }
  }
}