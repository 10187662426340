@charset "UTF-8";
.bg-color-1 {
  background-color: #FFF7E3; }

.bg-color-2 {
  background-color: #ECF9E9; }

.bg-color-3 {
  background-color: #E3F1F5; }

.bg-color-4 {
  background-color: #F5E3E3; }

.transition {
  transition: 0.3s all ease; }

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden !important; }

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box; }
  *::after, *::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    outline: 0; }
  *:active, *:focus, *:hover, *:visited {
    outline: 0;
    -webkit-tap-highlight-color: transparent; }

/* Webkit autofill background fix */
html body input:-webkit-autofill, html body input:-webkit-autofill:hover, html body input:-webkit-autofill:focus, html body input:-webkit-autofill:active, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, textarea:-webkit-autofill:active {
  transition: background-color 500000s ease-in-out 0s !important; }

@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: transparent; } }

input:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both; }

button, input, hr, optgroup, select, textarea, p, h1, h2, h3, h4, h5, h6 {
  margin: 0; }

progress, sub, sup {
  vertical-align: baseline; }

pre, code {
  overflow: auto; }

embed, iframe, img, legend, object, pre, details {
  max-width: 100%;
  display: block;
  border: 0;
  outline: 0; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

a {
  background-color: transparent;
  text-decoration: unset;
  color: unset; }
  a:active, a:focus, a:hover, a:visited {
    color: unset;
    text-decoration: unset; }

b, strong {
  font-weight: bold; }

code, kbd, samp, pre {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border: none;
  height: auto; }

button, select, input, textarea, label {
  text-transform: unset;
  border: none;
  outline: none;
  background: unset;
  appearance: none; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
  outline: ButtonText dotted 1px; }

textarea {
  width: 100%;
  display: block;
  resize: vertical; }

[type=checkbox], [type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

.hide, .hidden, [hidden] {
  display: none; }

body, button, input, optgroup, select, textarea {
  color: #404040;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.5; }

select::-ms-expand {
  display: none; }

input {
  border-radius: 0;
  box-shadow: none; }

cite, dfn, em, i {
  font-style: italic; }

blockquote {
  margin: 0 1.5em; }

address {
  margin: 0 0 1.5em; }

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: .9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  padding: 1.6em;
  color: #000; }

code, kbd, tt, var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: .9375rem; }

abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help; }

ins, mark {
  background: #fff9c0;
  text-decoration: none; }

big {
  font-size: 125%; }

ol, ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

ol {
  list-style-type: decimal; }

li > ol, li > ul {
  margin: 0;
  padding: 0; }

dt {
  font-weight: 700; }

dd {
  margin: 0 1.5em 1.5em; }

figure {
  margin: 0; }

table {
  margin: 0;
  width: auto; }

input[type=color]:focus, input[type=date]:focus, input[type=datetime-local]:focus, input[type=datetime]:focus, input[type=email]:focus, input[type=month]:focus, input[type=number]:focus, input[type=password]:focus, input[type=range]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=text]:focus, input[type=time]:focus, input[type=url]:focus, input[type=week]:focus, textarea:focus {
  color: inherit; }

a:active, a:focus, a:hover {
  color: inherit; }

input {
  appearance: none; }
  input[type=search] {
    appearance: none;
    outline: none; }
  input::-webkit-search-cancel-button {
    display: none;
    pointer-events: none;
    opacity: 0;
    visibility: hidden; }

@font-face {
  font-family: 'HK Nova';
  src: url("../fonts/HK-Nova/HKNova-Medium.eot");
  src: url("../fonts/HK-Nova/HKNova-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/HK-Nova/HKNova-Medium.woff2") format("woff2"), url("../fonts/HK-Nova/HKNova-Medium.woff") format("woff"), url("../fonts/HK-Nova/HKNova-Medium.ttf") format("truetype"), url("../fonts/HK-Nova/HKNova-Medium.svg#HKNova-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Proxima Nova Semibold';
  src: url("../fonts/ProximaNova/ProximaNova-Semibold.eot");
  src: url("../fonts/ProximaNova/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova/ProximaNova-Semibold.woff") format("woff"), url("../fonts/ProximaNova/ProximaNova-Semibold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

body {
  font-family: "HK Nova", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.6;
  color: #2D2D2D; }
  @media (max-width: 767px) {
    body {
      font-size: 14px; } }

.font-small {
  font-size: 14px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  line-height: 1.35;
  font-family: "HK Nova", sans-serif;
  font-style: normal;
  font-weight: 500; }

h1, .h1 {
  font-size: 50px; }
  @media (max-width: 991px) {
    h1, .h1 {
      font-size: 46px; } }

h2, .h2 {
  font-size: 44px; }
  @media (max-width: 991px) {
    h2, .h2 {
      font-size: 40px; } }

h3, .h3 {
  font-size: 40px; }
  @media (max-width: 991px) {
    h3, .h3 {
      font-size: 36px; } }
  @media (max-width: 767px) {
    h3, .h3 {
      font-size: 25px; } }

h4, .h4 {
  font-size: 34px; }
  @media (max-width: 767px) {
    h4, .h4 {
      font-size: 26px; } }

h5, .h5 {
  font-size: 24px; }

h6, .h6 {
  font-size: 22px; }

.link {
  cursor: pointer;
  transition: 0.3s all ease;
  display: inline-flex; }
  .link__inner {
    display: flex;
    position: relative; }
    .link__inner:after {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      transition: 0.3s all ease; }
  .link__caption {
    font-family: "HK Nova", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    transition: 0.3s all ease; }
  .link__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px; }
    .link__icon svg {
      width: 10px;
      height: auto;
      display: block;
      object-fit: contain; }
      .link__icon svg path {
        transition: 0.3s all ease; }
  .link.link-white .link__caption {
    color: #fff; }
  .link.link-white .link__icon svg path {
    stroke: #fff; }
  .link.link-white .link__inner:after {
    background-color: #fff; }
  .link.link-white:hover {
    opacity: .8; }
  .link.link-dark .link__caption {
    color: #2D2D2D; }
  .link.link-dark .link__icon svg path {
    stroke: #2D2D2D; }
  .link.link-dark .link__inner:after {
    background-color: #2D2D2D; }
  .link.link-dark:hover .link__caption {
    color: #006AB2; }
  .link.link-dark:hover .link__icon svg path {
    stroke: #006AB2; }
  .link.link-dark:hover .link__inner:after {
    background-color: #006AB2; }
  .link.link-blue .link__caption {
    color: #006AB2; }
  .link.link-blue .link__icon svg path {
    stroke: #006AB2; }
  .link.link-blue .link__inner:after {
    background-color: #006AB2; }
  .link.link-blue:hover {
    opacity: .8; }

.section-title {
  margin-bottom: 8px; }
  @media (max-width: 767px) {
    .section-title {
      font-size: 25px; } }

.post-content strong {
  color: #006AB2;
  font-weight: 500; }

.post-content p {
  margin-bottom: 2em;
  line-height: 1.7; }

.post-content img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain; }

/* Old Browser */
.browsers__placeholder {
  font-family: "Helvetica", sans-serif;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  min-width: 100vw;
  background-image: none !important;
  text-align: center;
  background-color: black !important;
  font-size: 20px;
  color: #fff;
  z-index: 100000000000000000000000000000000000;
  line-height: 1.6; }
  .browsers__placeholder p {
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 1.6;
    font-family: inherit; }
  .browsers__placeholder a {
    color: red; }

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
  clear: both; }

.overflow-hidden {
  overflow: hidden; }

.relative {
  position: relative; }

.aligncenter, .center {
  text-align: center; }

.alignleft, .left {
  text-align: left; }

.alignright, .right {
  text-align: right; }

.white {
  color: #fff; }

.above-lines {
  z-index: 25; }

.no-js {
  visibility: hidden;
  opacity: 0; }

.js {
  visibility: visible;
  opacity: 1; }

.swal2-styled.swal2-confirm {
  background-color: #006AB2 !important;
  color: #fff !important;
  border: 0 !important; }

.swal2-html-container {
  max-width: 75%;
  margin: 0 auto;
  line-height: 1.6; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto; }

@media (max-width: 575px) {
  .container {
    width: 100%;
    max-width: 100%; } }

@media (min-width: 576px) {
  .container {
    width: 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%; } }

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .container {
    width: 1110px;
    max-width: 100%; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.hidden-xs-up {
  display: none !important; }

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 991px) {
  html .hidden-md-down {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1199px) {
  html .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

.wait {
  cursor: wait; }

.template-wrap {
  position: relative;
  width: 100%;
  max-width: calc(100vw - var(--scroll-width));
  overflow-x: hidden; }

.lines-container {
  pointer-events: none;
  position: absolute !important;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15; }
  .lines-container__relative {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-between; }
  .lines-container .line {
    position: relative;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 0.5px; }
  .lines-container.white-lines .line {
    background-color: rgba(255, 255, 255, 0.1); }
  .lines-container.dark-lines .line {
    background-color: rgba(180, 197, 215, 0.2); }
  @media (max-width: 575px) {
    .lines-container .line:nth-child(6) {
      display: none; }
    .lines-container .line:nth-child(7) {
      display: none; } }

.cookie-banner {
  filter: drop-shadow(7px 14px 44px rgba(0, 0, 0, 0.25));
  position: fixed;
  width: 400px;
  height: 250px;
  bottom: 50px;
  right: 50px;
  z-index: 250;
  background-color: #fff;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
  transition: 0.3s all ease; }
  .cookie-banner.active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0); }
  .cookie-banner .cookie-img {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(26%) translateY(-24%);
    pointer-events: none; }
  .cookie-banner__wrap {
    padding-left: 35px;
    padding-top: 30px;
    padding-bottom: 25px;
    padding-right: 20px;
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch; }
  .cookie-banner p {
    max-width: 270px;
    font-family: "HK Nova", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.4;
    letter-spacing: 0.05em;
    color: #7C91A6; }
  .cookie-banner .btn-accept-cookie {
    margin-top: 20px; }
  @media (max-width: 991px) {
    .cookie-banner {
      right: 30px; } }
  @media (max-width: 480px) {
    .cookie-banner {
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: auto; }
      .cookie-banner p {
        max-width: 100%;
        width: 100%;
        display: block; }
      .cookie-banner .cookie-img {
        max-height: 150px; }
      .cookie-banner__wrap {
        padding-left: 35px;
        padding-right: 35px;
        padding-top: 85px;
        padding-bottom: 30px; }
      .cookie-banner .btn-accept-cookie {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 33px; } }

.consultation {
  background-color: #EAF6FF;
  border: 1px solid #45A6DC;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 35px;
  padding-right: 35px; }
  @media (max-width: 767px) {
    .consultation {
      padding-top: 50px;
      padding-bottom: 50px;
      padding-left: 15px;
      padding-right: 15px; }
      .consultation .link__caption {
        text-decoration: underline; }
      .consultation .link__inner:after {
        display: none; }
      .consultation .link__icon {
        display: none; } }

.nav-main {
  position: absolute;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%; }
  .nav-main__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
  .nav-main .left-part {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .nav-main .right-part {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .nav-main .menu {
    display: flex;
    flex-wrap: wrap; }
    .nav-main .menu > li {
      position: relative;
      margin-left: 40px;
      padding: 10px 0;
      display: flex; }
      .nav-main .menu > li > a {
        display: flex;
        font-family: "Proxima Nova Semibold";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #fff; }
        .nav-main .menu > li > a .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 5px; }
          .nav-main .menu > li > a .icon svg {
            transition: 0.3s all ease;
            height: 5px;
            width: auto;
            display: block;
            object-fit: contain; }
      .nav-main .menu > li .sub-menu {
        left: -28px;
        top: 99%;
        position: absolute;
        width: 240px;
        background-color: #fff;
        padding-left: 30px;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 15px;
        transition: 0.3s all ease;
        opacity: 0;
        pointer-events: none;
        transform: translateY(15px); }
        .nav-main .menu > li .sub-menu li a {
          font-family: "HK Nova", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 2.5;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #2D2D2D;
          display: flex;
          align-items: center;
          transition: 0.3s all ease; }
          .nav-main .menu > li .sub-menu li a:hover {
            color: #006AB2; }
      .nav-main .menu > li:hover > a .icon svg {
        transform: rotate(180deg); }
      .nav-main .menu > li:hover .sub-menu {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0); }
  .nav-main .nav-toggle {
    display: none; }
  .nav-main__logo img {
    max-height: 72px;
    display: block;
    width: auto;
    object-fit: contain; }
  @media (max-width: 991px) {
    .nav-main .menu {
      display: none; }
    .nav-main .nav-toggle {
      display: block;
      width: 56px;
      cursor: pointer;
      padding: 8px;
      margin-right: -8px; }
      .nav-main .nav-toggle span {
        display: block;
        margin: 10px 0;
        height: 2px;
        width: 40px;
        background-color: #fff; }
        .nav-main .nav-toggle span:first-child {
          margin-top: 0; }
        .nav-main .nav-toggle span:last-child {
          margin-bottom: 0; } }
  @media (max-width: 767px) {
    .nav-main__logo img {
      max-height: 50px; } }

.nav-side {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 450px;
  background: linear-gradient(214.69deg, #72D2FE -2.73%, #45A6DC 55.53%);
  box-shadow: -10px 0px 44px rgba(0, 0, 0, 0.15);
  z-index: 150;
  max-height: calc(var(--vh, 1vh) * 100);
  pointer-events: none;
  transform: translateX(105%);
  max-width: 100%;
  transition: 0.3s all ease; }
  .nav-side.active {
    opacity: 1;
    transform: translateX(0);
    pointer-events: all; }
  .nav-side .nav-close {
    right: 30px;
    top: 30px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute; }
  .nav-side__scroller {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding-top: 170px;
    padding-bottom: 125px; }
  .nav-side .menu > li {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 45px; }
    .nav-side .menu > li.parent > a {
      margin-left: 20px; }
    .nav-side .menu > li > a {
      font-family: "HK Nova", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.3;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #fff;
      padding: 10px;
      display: flex;
      align-items: center; }
      .nav-side .menu > li > a .icon {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s all ease; }
        .nav-side .menu > li > a .icon.active {
          transform: rotate(180deg); }
        .nav-side .menu > li > a .icon svg {
          height: 8px;
          width: auto;
          object-fit: contain;
          margin-left: 5px; }
  .nav-side .menu .sub-menu {
    width: 100%;
    padding-top: 30px;
    display: none; }
    .nav-side .menu .sub-menu li {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 25px; }
      .nav-side .menu .sub-menu li:last-child {
        margin-bottom: 0; }
      .nav-side .menu .sub-menu li a {
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.3;
        padding: 10px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #D4F1FA; }
  @media (max-width: 465px) {
    .nav-side .nav-close {
      top: 16px;
      right: 16px; }
      .nav-side .nav-close svg {
        height: 22px;
        width: auto;
        display: block;
        object-fit: contain; }
    .nav-side__scroller {
      padding-top: 18vh; }
    .nav-side .menu > li {
      margin-bottom: 36px; } }

.footer {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 80px;
  background: linear-gradient(213.19deg, #72D2FE 1.48%, #389CD3 50.32%);
  color: #fff; }
  .footer__top {
    display: flex; }
    .footer__top-left {
      width: 360px; }
      .footer__top-left .link {
        margin-top: 20px; }
    .footer__top-right {
      width: calc(100% - 360px);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 120px; }
      .footer__top-right .link {
        display: none; }
      .footer__top-right .contact-info-list {
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between; }
        .footer__top-right .contact-info-list .list-item {
          display: inline-flex;
          align-items: center; }
          .footer__top-right .contact-info-list .list-item .icon {
            margin-right: 10px; }
            .footer__top-right .contact-info-list .list-item .icon svg {
              max-height: 20px;
              width: auto;
              display: block;
              object-fit: contain; }
          .footer__top-right .contact-info-list .list-item .caption {
            font-family: "HK Nova", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 1;
            color: #fff; }
  .footer .form-wrap {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 80px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    display: none; }
    .footer .form-wrap.active {
      display: flex; }
  .footer .form {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }
    .footer .form .form-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: -10px;
      margin-right: -10px; }
      .footer .form .form-row .form-group {
        flex-grow: 1;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px; }
        .footer .form .form-row .form-group label {
          font-family: "HK Nova", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 1.25;
          letter-spacing: 0.05em;
          color: #fff;
          margin-bottom: 10px;
          display: inline-block;
          cursor: pointer; }
        .footer .form .form-row .form-group input {
          display: block;
          height: 60px;
          width: 100%;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid #AFE1F0;
          padding-left: 20px;
          padding-right: 15px;
          font-family: "HK Nova", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          color: #fff;
          letter-spacing: 0.05em; }
          .footer .form .form-row .form-group input::placeholder {
            font-family: "HK Nova", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: rgba(255, 255, 255, 0.4); }
        .footer .form .form-row .form-group textarea {
          display: block;
          min-height: 120px;
          resize: vertical;
          max-height: 250px;
          width: 100%;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid #AFE1F0;
          padding: 15px 20px;
          font-family: "HK Nova", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          color: #fff;
          letter-spacing: 0.05em; }
          .footer .form .form-row .form-group textarea::placeholder {
            font-family: "HK Nova", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: rgba(255, 255, 255, 0.4); }
    .footer .form .submit-group {
      margin-top: 45px;
      display: flex;
      justify-content: center;
      align-items: center; }
  .footer__bot {
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    line-height: 1.55; }
    .footer__bot-col {
      width: calc(100% / 3);
      padding-bottom: 40px; }
      .footer__bot-col .caption {
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 20px;
        display: block; }
      .footer__bot-col ul {
        list-style-type: none; }
        .footer__bot-col ul li {
          list-style: none; }
      .footer__bot-col ul li, .footer__bot-col p, .footer__bot-col address {
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 1.7;
        letter-spacing: 0.05em;
        color: rgba(255, 255, 255, 0.7); }
      .footer__bot-col:nth-child(1) {
        text-align: left; }
      .footer__bot-col:nth-child(2) {
        text-align: center; }
      .footer__bot-col:nth-child(3) {
        text-align: right; }
  .footer .copyright {
    text-align: center;
    font-family: "HK Nova", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.4); }
  @media (max-width: 1199px) {
    .footer__top {
      max-width: 720px;
      margin-left: auto;
      margin-right: auto; }
      .footer__top-right {
        height: auto;
        display: block; }
        .footer__top-right .contact-info-list {
          padding-top: 10px;
          flex-direction: column;
          align-items: flex-start; }
          .footer__top-right .contact-info-list .list-item {
            margin-bottom: 30px; } }
  @media (max-width: 767px) {
    .footer__top {
      flex-wrap: wrap; }
      .footer__top-left {
        width: 100%;
        max-width: 100%; }
        .footer__top-left .section-title {
          text-align: center;
          max-width: 250px;
          margin-left: auto;
          margin-right: auto; }
        .footer__top-left .link {
          display: none; }
      .footer__top-right {
        width: 100%;
        max-width: 100%; }
        .footer__top-right .contact-info-list {
          margin-top: 40px;
          margin-bottom: 12px;
          justify-content: center;
          align-items: flex-start; }
          .footer__top-right .contact-info-list .list-item {
            justify-content: center;
            text-align: center;
            margin-left: auto;
            margin-right: auto; }
        .footer__top-right .link {
          display: inline-flex;
          position: relative;
          left: 50%;
          transform: translateX(-50%); } }
  @media (max-width: 666px) {
    .footer__bot-col {
      width: 100%;
      max-width: 100%;
      text-align: center !important;
      padding-bottom: 45px; } }

.footer-light {
  padding-top: 260px;
  width: 100%;
  padding-bottom: 80px;
  background: #fff;
  color: #2D2D2D; }
  .footer-light__container:before {
    border-bottom: 1px solid #C0C0C0;
    width: 100%;
    display: block;
    content: ''; }
  .footer-light__bot {
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    line-height: 1.55; }
    .footer-light__bot-col {
      width: calc(100% / 3);
      padding-bottom: 40px; }
      .footer-light__bot-col .caption {
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #2D2D2D;
        margin-bottom: 20px;
        display: block; }
      .footer-light__bot-col ul {
        list-style-type: none; }
        .footer-light__bot-col ul li {
          list-style: none; }
      .footer-light__bot-col ul li, .footer-light__bot-col p, .footer-light__bot-col address {
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 1.7;
        letter-spacing: 0.05em;
        color: rgba(45, 45, 45, 0.7); }
      .footer-light__bot-col:nth-child(1) {
        text-align: left; }
      .footer-light__bot-col:nth-child(2) {
        text-align: center; }
      .footer-light__bot-col:nth-child(3) {
        text-align: right; }
  .footer-light .copyright {
    text-align: center;
    font-family: "HK Nova", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(45, 45, 45, 0.4); }
  @media (max-width: 1199px) {
    .footer-light {
      padding-top: 120px; } }
  @media (max-width: 666px) {
    .footer-light {
      padding-top: 0; }
      .footer-light__container:before {
        display: none; }
      .footer-light__bot-col {
        width: 100%;
        max-width: 100%;
        text-align: center !important;
        padding-bottom: 45px; } }

.page-hero-section {
  width: 100%;
  position: relative;
  min-height: 600px;
  height: auto;
  z-index: 10;
  padding-top: calc(60px + 70px + 50px);
  padding-bottom: 100px; }
  .page-hero-section .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    z-index: 1; }
  .page-hero-section .container {
    position: relative;
    z-index: 10; }
  .page-hero-section .heading {
    max-width: 880px; }
  .page-hero-section .sub-heading {
    margin-bottom: 20px; }
  .page-hero-section .links-wrap {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap; }
    .page-hero-section .links-wrap .link {
      margin-right: 55px; }
      .page-hero-section .links-wrap .link:last-child {
        margin-right: 0; }
  @media (max-width: 991px) {
    .page-hero-section {
      padding-bottom: 120px; }
      .page-hero-section .links-wrap {
        margin-top: 40px; }
        .page-hero-section .links-wrap .link {
          margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .page-hero-section {
      padding-bottom: 80px;
      padding-top: calc(60px + 90px); }
      .page-hero-section .heading {
        text-align: center; }
      .page-hero-section .sub-heading {
        text-align: center;
        margin-bottom: 35px; }
      .page-hero-section .links-wrap {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .page-hero-section .links-wrap .link {
          margin-left: auto !important;
          margin-right: auto !important; } }

.page-home .client-group img {
  filter: grayscale(1); }

.page-home .hero-section {
  width: 100%;
  position: relative;
  height: 1100px;
  z-index: 10;
  padding-top: calc(90px + 75px + 50px); }
  .page-home .hero-section .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    z-index: 1; }
  .page-home .hero-section .container {
    position: relative;
    z-index: 10; }
  .page-home .hero-section .heading {
    max-width: 880px; }
  .page-home .hero-section .sub-heading {
    margin-bottom: 20px; }
  .page-home .hero-section .slider-about {
    padding: 30px 0;
    margin-top: 45px; }
    .page-home .hero-section .slider-about .swiper-slide {
      position: relative;
      width: 250px;
      height: auto;
      border: 0.5px solid rgba(255, 255, 255, 0.5);
      border-top: 0;
      margin-right: 40px;
      padding-left: 30px;
      padding-bottom: 20px;
      padding-right: 7px;
      padding-top: 50px; }
      .page-home .hero-section .slider-about .swiper-slide:nth-child(1) .notice {
        position: absolute;
        left: 0;
        top: 50%;
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #fff;
        transform: translateY(-50%) translateX(-100%) rotate(-90deg); }
      .page-home .hero-section .slider-about .swiper-slide .number {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: #fff;
        transform: translateY(-50%); }
        .page-home .hero-section .slider-about .swiper-slide .number span {
          padding-left: 55px; }
        .page-home .hero-section .slider-about .swiper-slide .number:before {
          content: "";
          width: 40px;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.5);
          display: block;
          position: absolute;
          left: 0; }
        .page-home .hero-section .slider-about .swiper-slide .number:after {
          content: "";
          width: 155px;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.5);
          display: block;
          position: absolute;
          right: 0; }
      .page-home .hero-section .slider-about .swiper-slide .about-item__content {
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.4;
        color: #fff; }
  .page-home .hero-section .scroll-circle {
    width: 250px;
    height: 250px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 100%;
    transform: translateX(-50%) translateY(50%);
    position: absolute;
    bottom: 0;
    left: 50%; }
    .page-home .hero-section .scroll-circle__relative {
      position: relative;
      width: 100%;
      height: 100%; }
    .page-home .hero-section .scroll-circle .circle-half {
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px; }
      .page-home .hero-section .scroll-circle .circle-half .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px; }
        .page-home .hero-section .scroll-circle .circle-half .icon svg {
          height: 16px;
          width: auto;
          display: block;
          object-fit: contain; }
      .page-home .hero-section .scroll-circle .circle-half .section-label {
        text-align: center;
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 1.82;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #fff; }
  @media (max-width: 991px) {
    .page-home .hero-section {
      height: auto;
      padding-top: calc(60px + 75px + 50px);
      padding-bottom: 240px; } }
  @media (max-width: 575px) {
    .page-home .hero-section .sub-heading {
      max-width: 320px; } }

.page-home .services-grid {
  max-width: 2440px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .page-home .services-grid .service-item {
    width: 50%;
    position: relative; }
    .page-home .services-grid .service-item .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      z-index: 1; }
    .page-home .services-grid .service-item:nth-child(odd) {
      display: flex;
      justify-content: flex-end; }
    .page-home .services-grid .service-item:nth-child(even) {
      display: flex;
      justify-content: flex-start; }
    .page-home .services-grid .service-item__small .service-item__inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .page-home .services-grid .service-item__small .service-item__inner .service-item__description {
        max-width: 60%;
        margin-bottom: 0; }
    .page-home .services-grid .service-item__inner {
      width: 100%;
      max-width: 720px;
      position: relative;
      z-index: 10;
      padding-left: 90px;
      padding-right: 75px;
      padding-bottom: 75px;
      padding-top: 80px;
      color: #fff; }
      .page-home .services-grid .service-item__inner .link {
        position: absolute;
        bottom: 75px;
        right: 75px; }
    .page-home .services-grid .service-item__title {
      text-transform: uppercase;
      margin-bottom: 20px; }
    .page-home .services-grid .service-item__description {
      margin-bottom: 35px; }
    .page-home .services-grid .service-item__content ul {
      margin-bottom: 10px; }
      .page-home .services-grid .service-item__content ul:last-child {
        margin-bottom: 0; }
      .page-home .services-grid .service-item__content ul li {
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 1.85;
        color: #fff;
        position: relative; }
        .page-home .services-grid .service-item__content ul li:before {
          display: inline-block;
          content: "•";
          font-size: inherit;
          color: inherit;
          margin-right: 10px; }
  @media (max-width: 1199px) {
    .page-home .services-grid .service-item__inner .link {
      margin-top: 70px;
      position: static; } }
  @media (max-width: 1024px) {
    .page-home .services-grid .service-item__inner {
      padding-top: 80px;
      padding-bottom: 60px;
      padding-left: 40px;
      padding-right: 20px; }
    .page-home .services-grid .service-item__description {
      font-size: 14px; }
    .page-home .services-grid .service-item__content {
      font-size: 16px; }
    .page-home .services-grid .service-item__small .service-item__inner .service-item__description {
      max-width: 100%;
      font-size: 18px; } }
  @media (max-width: 767px) {
    .page-home .services-grid .service-item {
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center !important;
      align-items: center; }
      .page-home .services-grid .service-item__inner {
        padding-left: 40px;
        padding-right: 40px; }
        .page-home .services-grid .service-item__inner .link {
          position: relative;
          left: 50%;
          right: auto;
          bottom: auto;
          transform: translateX(-50%); }
      .page-home .services-grid .service-item__description {
        display: none; }
      .page-home .services-grid .service-item__title {
        text-align: center;
        margin-bottom: 40px; }
      .page-home .services-grid .service-item__content {
        font-size: 14px;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto; } }
  @media (max-width: 575px) {
    .page-home .services-grid .service-item__inner {
      padding-left: 15px;
      padding-right: 15px; }
    .page-home .services-grid .service-item__small .service-item__inner .service-item__description {
      padding-top: 60px;
      display: block;
      font-size: 14px;
      line-height: 2; } }

.page-home .section-about {
  background-color: #fff;
  padding-top: 115px;
  padding-bottom: 125px; }
  .page-home .section-about .bg {
    background: linear-gradient(214.69deg, #72d2fe -2.73%, #45a6dc 55.53%);
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; }
  .page-home .section-about .heading-flex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
  .page-home .section-about .section-subtitle {
    max-width: 360px; }
  .page-home .section-about .link-bot {
    display: none; }
  @media (max-width: 991px) {
    .page-home .section-about {
      padding-top: 100px;
      padding-bottom: 85px; }
      .page-home .section-about .heading-flex {
        align-items: center; } }
  @media (max-width: 767px) {
    .page-home .section-about {
      padding-top: 80px; }
      .page-home .section-about .heading-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .page-home .section-about .heading-flex .link {
          display: none; }
      .page-home .section-about .section-title {
        text-align: center; }
      .page-home .section-about .section-subtitle {
        text-align: center;
        max-width: 290px;
        margin-left: auto;
        margin-right: auto; }
      .page-home .section-about .link-bot {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 50px; } }

.page-home .section-clients {
  padding: 160px 0; }
  .page-home .section-clients .clients-block {
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
    margin-top: 65px; }
    .page-home .section-clients .clients-block .link {
      margin-top: 20px; }
  .page-home .section-clients .client-group {
    width: 100%;
    margin-bottom: 60px; }
    .page-home .section-clients .client-group__title {
      line-height: 1;
      display: block;
      padding-bottom: 10px;
      border-bottom: 1px solid #000;
      text-transform: uppercase;
      font-family: "HK Nova", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.05em;
      color: #040C1E; }
    .page-home .section-clients .client-group__row {
      margin-top: 30px;
      display: flex;
      justify-content: space-between; }
      .page-home .section-clients .client-group__row img {
        max-height: 115px;
        max-width: calc(100% / 3 - 40px);
        object-fit: contain;
        display: block; }
  @media (max-width: 991px) {
    .page-home .section-clients {
      padding: 80px 0; } }
  @media (max-width: 767px) {
    .page-home .section-clients .clients-block {
      margin-top: 55px;
      width: 100%;
      display: block; }
      .page-home .section-clients .clients-block .link {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 20px; }
    .page-home .section-clients .section-title {
      text-align: center;
      display: block;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto; }
    .page-home .section-clients .client-group__row {
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      overflow-x: auto;
      width: 100%;
      -webkit-overflow-scrolling: touch; }
      .page-home .section-clients .client-group__row img {
        width: auto;
        max-width: unset;
        max-height: 50px;
        margin-right: 70px; } }

.page-home .section-refs {
  padding-top: 60px;
  padding-bottom: 140px; }
  @media (max-width: 767px) {
    .page-home .section-refs {
      padding-top: 20px;
      padding-bottom: 100px; }
      .page-home .section-refs .section-title {
        text-align: center; }
      .page-home .section-refs .section-subtitle {
        text-align: center; } }

.page-home .services-section {
  padding: 140px 0; }
  .page-home .services-section .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    z-index: 1;
    background: linear-gradient(214.69deg, #72d2fe -2.73%, #45a6dc 55.53%);
    opacity: 0.08; }
  .page-home .services-section .section-header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .page-home .services-section .slider-arrows {
    display: flex;
    align-items: center; }
    .page-home .services-section .slider-arrows__inner {
      position: relative;
      width: 85px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .page-home .services-section .slider-arrows .prev,
    .page-home .services-section .slider-arrows .next {
      height: 25px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 5px; }
      .page-home .services-section .slider-arrows .prev .arrow,
      .page-home .services-section .slider-arrows .next .arrow {
        position: relative; }
        .page-home .services-section .slider-arrows .prev .arrow:after, .page-home .services-section .slider-arrows .prev .arrow:before,
        .page-home .services-section .slider-arrows .next .arrow:after,
        .page-home .services-section .slider-arrows .next .arrow:before {
          transition: 0.3s all ease; }
        .page-home .services-section .slider-arrows .prev .arrow span,
        .page-home .services-section .slider-arrows .next .arrow span {
          transition: 0.3s all ease; }
      .page-home .services-section .slider-arrows .prev.swiper-button-disabled,
      .page-home .services-section .slider-arrows .next.swiper-button-disabled {
        pointer-events: none; }
        .page-home .services-section .slider-arrows .prev.swiper-button-disabled .arrow:after, .page-home .services-section .slider-arrows .prev.swiper-button-disabled .arrow:before,
        .page-home .services-section .slider-arrows .next.swiper-button-disabled .arrow:after,
        .page-home .services-section .slider-arrows .next.swiper-button-disabled .arrow:before {
          background-color: #b4c5d7; }
        .page-home .services-section .slider-arrows .prev.swiper-button-disabled .arrow span,
        .page-home .services-section .slider-arrows .next.swiper-button-disabled .arrow span {
          background-color: #b4c5d7; }
      .page-home .services-section .slider-arrows .prev:hover .arrow span,
      .page-home .services-section .slider-arrows .next:hover .arrow span {
        width: 52px; }
    .page-home .services-section .slider-arrows .prev {
      position: absolute;
      margin-right: 12px;
      right: 50%; }
      .page-home .services-section .slider-arrows .prev .arrow span {
        display: block;
        height: 2px;
        background-color: #2D2D2D;
        width: 34px;
        border-radius: 10px; }
      .page-home .services-section .slider-arrows .prev .arrow:before {
        display: block;
        content: "";
        width: 2px;
        height: 8px;
        background-color: #2D2D2D;
        border-radius: 10px;
        position: absolute;
        left: 2px;
        bottom: -1px;
        transform: rotate(50deg); }
      .page-home .services-section .slider-arrows .prev .arrow:after {
        display: block;
        content: "";
        width: 2px;
        height: 8px;
        background-color: #2D2D2D;
        border-radius: 10px;
        position: absolute;
        left: 2px;
        top: -1px;
        transform: rotate(-50deg); }
    .page-home .services-section .slider-arrows .next {
      position: absolute;
      left: 50%; }
      .page-home .services-section .slider-arrows .next .arrow span {
        display: block;
        height: 2px;
        background-color: #2D2D2D;
        width: 34px;
        border-radius: 10px; }
      .page-home .services-section .slider-arrows .next .arrow:before {
        display: block;
        content: "";
        width: 2px;
        height: 8px;
        background-color: #2D2D2D;
        border-radius: 10px;
        position: absolute;
        right: 2px;
        bottom: -1px;
        transform: rotate(-50deg); }
      .page-home .services-section .slider-arrows .next .arrow:after {
        display: block;
        content: "";
        width: 2px;
        height: 8px;
        background-color: #2D2D2D;
        border-radius: 10px;
        position: absolute;
        right: 2px;
        top: -1px;
        transform: rotate(50deg); }
  .page-home .services-section .slider-services {
    margin-top: 40px;
    margin-bottom: 50px; }
    .page-home .services-section .slider-services .swiper-slide {
      width: 360px;
      display: flex;
      height: auto;
      margin-right: 40px; }
      .page-home .services-section .slider-services .swiper-slide:last-child {
        margin-right: 0; }
      .page-home .services-section .slider-services .swiper-slide .service-item {
        width: 100%;
        background-color: #fff;
        padding-top: 40px;
        padding-left: 50px;
        padding-right: 30px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
        .page-home .services-section .slider-services .swiper-slide .service-item__icon {
          position: relative;
          width: 70px;
          height: 70px;
          border-radius: 8px;
          margin-bottom: 30px; }
          .page-home .services-section .slider-services .swiper-slide .service-item__icon img,
          .page-home .services-section .slider-services .swiper-slide .service-item__icon svg {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateX(10px) translateY(-50%);
            max-height: 50px;
            width: auto;
            display: block;
            object-fit: contain; }
        .page-home .services-section .slider-services .swiper-slide .service-item__title {
          margin-bottom: 12px;
          font-family: "HK Nova", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 1.35;
          letter-spacing: 0.05em;
          color: #2D2D2D; }
        .page-home .services-section .slider-services .swiper-slide .service-item__description {
          font-family: "HK Nova", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 1.7;
          color: rgba(45, 45, 45, 0.75); }
        .page-home .services-section .slider-services .swiper-slide .service-item__permalink {
          margin-top: 35px;
          display: inline-flex;
          align-items: center;
          font-family: "HK Nova", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 1.35;
          color: #006AB2;
          transition: 0.3s all ease; }
  @media (max-width: 767px) {
    .page-home .services-section {
      padding-top: 80px;
      padding-bottom: 80px; }
      .page-home .services-section .section-header {
        justify-content: center;
        align-items: center; }
        .page-home .services-section .section-header .section-title {
          text-align: center; }
        .page-home .services-section .section-header .slider-arrows {
          display: none; }
      .page-home .services-section .slider-services {
        margin-top: 40px;
        margin-bottom: 40px; }
        .page-home .services-section .slider-services .swiper-slide {
          width: 270px;
          margin-right: 15px; }
          .page-home .services-section .slider-services .swiper-slide .service-item {
            padding-top: 40px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 40px; }
      .page-home .services-section .link {
        position: relative;
        left: 50%;
        transform: translateX(-50%); } }

.team-slider-about {
  margin-top: 75px;
  padding: 10px 0; }
  .team-slider-about .swiper-slide {
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 40px; }
    .team-slider-about .swiper-slide .avatar {
      min-width: 360px;
      width: 360px;
      display: flex;
      align-items: center; }
      .team-slider-about .swiper-slide .avatar img {
        border-radius: 100%;
        overflow: hidden; }
    .team-slider-about .swiper-slide .info {
      padding-left: 90px; }
      .team-slider-about .swiper-slide .info .position {
        margin-top: 16px;
        margin-bottom: 18px;
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.56;
        color: #006AB2; }
      .team-slider-about .swiper-slide .info .description {
        max-width: 540px; }
  .team-slider-about .slider-dots {
    margin-top: 65px;
    margin-left: calc(360px + 90px);
    display: flex;
    flex-wrap: wrap;
    padding-left: 6px; }
    .team-slider-about .slider-dots .swiper-pagination-bullet {
      background-color: transparent;
      opacity: 1;
      margin: 0 6px;
      width: 20px;
      height: 15px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s all ease;
      cursor: pointer; }
      .team-slider-about .slider-dots .swiper-pagination-bullet:after {
        width: 100%;
        height: 2px;
        background-color: #b4c5d7;
        border-radius: 0;
        transition: 0.3s all ease;
        display: block;
        content: ""; }
      .team-slider-about .slider-dots .swiper-pagination-bullet:first-child {
        margin-left: 0; }
      .team-slider-about .slider-dots .swiper-pagination-bullet:last-child {
        margin-right: 0; }
      .team-slider-about .slider-dots .swiper-pagination-bullet.swiper-pagination-bullet-active:after, .team-slider-about .slider-dots .swiper-pagination-bullet:hover:after {
        background-color: #006AB2; }
      .team-slider-about .slider-dots .swiper-pagination-bullet.swiper-pagination-bullet-active {
        width: 30px; }

@media (max-width: 991px) {
  .team-slider-about .swiper-slide {
    align-items: flex-start; }
    .team-slider-about .swiper-slide .avatar {
      width: 240px;
      min-width: 240px; }
    .team-slider-about .swiper-slide .info {
      padding-left: 43px; }
      .team-slider-about .swiper-slide .info .description {
        max-width: 100%; }
  .team-slider-about .slider-dots {
    margin-left: calc(240px + 43px); } }

@media (max-width: 767px) {
  .team-slider-about {
    margin-top: 40px; }
    .team-slider-about .swiper-slide {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-right: 40px; }
      .team-slider-about .swiper-slide .avatar {
        width: 100%;
        min-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
        .team-slider-about .swiper-slide .avatar img {
          border-radius: 100%;
          overflow: hidden;
          max-width: 240px; }
      .team-slider-about .swiper-slide .info {
        padding: 0;
        padding-top: 15px; }
        .team-slider-about .swiper-slide .info .name {
          text-align: center; }
        .team-slider-about .swiper-slide .info .position {
          text-align: center;
          margin-top: 8px;
          margin-bottom: 10px; }
        .team-slider-about .swiper-slide .info .description {
          text-align: left;
          font-size: 18px; }
    .team-slider-about .slider-dots {
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center; } }

.refs-slider {
  overflow: unset;
  margin-top: 100px; }
  .refs-slider .swiper-slide {
    display: flex;
    justify-content: center; }
    .refs-slider .swiper-slide .ref-item {
      position: relative;
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center; }
      .refs-slider .swiper-slide .ref-item .info .message {
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 1.35;
        color: #2D2D2D;
        margin-bottom: 20px;
        font-size: 32px;
        max-width: 85%; }
      .refs-slider .swiper-slide .ref-item .info .position {
        color: #006AB2; }
      .refs-slider .swiper-slide .ref-item .avatar {
        z-index: -1;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%) translateX(calc(50% + 140px));
        width: 400px;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .refs-slider .swiper-slide .ref-item .avatar img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 100%;
          overflow: hidden; }
  .refs-slider .slider-arrows {
    width: 100%;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 95px;
    display: flex;
    align-items: center;
    padding-left: 2px; }
    .refs-slider .slider-arrows__inner {
      position: relative;
      width: 85px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .refs-slider .slider-arrows .prev,
    .refs-slider .slider-arrows .next {
      height: 25px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 5px; }
      .refs-slider .slider-arrows .prev .arrow,
      .refs-slider .slider-arrows .next .arrow {
        position: relative; }
        .refs-slider .slider-arrows .prev .arrow:before, .refs-slider .slider-arrows .prev .arrow:after,
        .refs-slider .slider-arrows .next .arrow:before,
        .refs-slider .slider-arrows .next .arrow:after {
          transition: 0.3s all ease; }
        .refs-slider .slider-arrows .prev .arrow span,
        .refs-slider .slider-arrows .next .arrow span {
          transition: 0.3s all ease; }
      .refs-slider .slider-arrows .prev.swiper-button-disabled,
      .refs-slider .slider-arrows .next.swiper-button-disabled {
        pointer-events: none; }
        .refs-slider .slider-arrows .prev.swiper-button-disabled .arrow:after, .refs-slider .slider-arrows .prev.swiper-button-disabled .arrow:before,
        .refs-slider .slider-arrows .next.swiper-button-disabled .arrow:after,
        .refs-slider .slider-arrows .next.swiper-button-disabled .arrow:before {
          background-color: #b4c5d7; }
        .refs-slider .slider-arrows .prev.swiper-button-disabled .arrow span,
        .refs-slider .slider-arrows .next.swiper-button-disabled .arrow span {
          background-color: #b4c5d7; }
      .refs-slider .slider-arrows .prev:hover .arrow span,
      .refs-slider .slider-arrows .next:hover .arrow span {
        width: 52px; }
    .refs-slider .slider-arrows .prev {
      position: absolute;
      margin-right: 12px;
      right: 50%; }
      .refs-slider .slider-arrows .prev .arrow span {
        display: block;
        height: 2px;
        background-color: #006ab2;
        width: 34px;
        border-radius: 10px; }
      .refs-slider .slider-arrows .prev .arrow:before {
        display: block;
        content: "";
        width: 2px;
        height: 8px;
        background-color: #006ab2;
        border-radius: 10px;
        position: absolute;
        left: 2px;
        bottom: -1px;
        transform: rotate(50deg); }
      .refs-slider .slider-arrows .prev .arrow:after {
        display: block;
        content: "";
        width: 2px;
        height: 8px;
        background-color: #006ab2;
        border-radius: 10px;
        position: absolute;
        left: 2px;
        top: -1px;
        transform: rotate(-50deg); }
    .refs-slider .slider-arrows .next {
      position: absolute;
      left: 50%; }
      .refs-slider .slider-arrows .next .arrow span {
        display: block;
        height: 2px;
        background-color: #006ab2;
        width: 34px;
        border-radius: 10px; }
      .refs-slider .slider-arrows .next .arrow:before {
        display: block;
        content: "";
        width: 2px;
        height: 8px;
        background-color: #006ab2;
        border-radius: 10px;
        position: absolute;
        right: 2px;
        bottom: -1px;
        transform: rotate(-50deg); }
      .refs-slider .slider-arrows .next .arrow:after {
        display: block;
        content: "";
        width: 2px;
        height: 8px;
        background-color: #006ab2;
        border-radius: 10px;
        position: absolute;
        right: 2px;
        top: -1px;
        transform: rotate(50deg); }
  @media (max-width: 1600px) {
    .refs-slider .swiper-slide .ref-item {
      width: 100%; }
      .refs-slider .swiper-slide .ref-item .info {
        max-width: 565px; }
        .refs-slider .swiper-slide .ref-item .info .message {
          font-size: 30px; }
      .refs-slider .swiper-slide .ref-item .avatar {
        width: 315px;
        height: 315px;
        transform: translateY(-50%) translateX(50%); }
    .refs-slider .slider-arrows {
      margin-top: 70px; } }
  @media (max-width: 991px) {
    .refs-slider .swiper-slide .ref-item {
      width: 100%; }
      .refs-slider .swiper-slide .ref-item .info {
        max-width: 440px; }
        .refs-slider .swiper-slide .ref-item .info .message {
          max-width: 100%;
          font-size: 28px; }
      .refs-slider .swiper-slide .ref-item .avatar {
        width: 290px;
        height: 290px;
        transform: translateY(-50%) translateX(0); }
    .refs-slider .slider-arrows {
      margin-top: 70px; } }
  @media (max-width: 767px) {
    .refs-slider {
      margin-top: 30px; }
      .refs-slider .swiper-slide .ref-item {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center; }
        .refs-slider .swiper-slide .ref-item .info {
          width: 100%;
          max-width: 480px;
          margin-left: auto;
          margin-right: auto; }
          .refs-slider .swiper-slide .ref-item .info .message {
            font-size: 24px;
            text-align: center; }
          .refs-slider .swiper-slide .ref-item .info .position {
            text-align: center; }
        .refs-slider .swiper-slide .ref-item .avatar {
          width: 240px;
          height: 240px;
          transform: none;
          position: static;
          margin-bottom: 25px; }
      .refs-slider .slider-arrows {
        margin-top: 30px;
        justify-content: center; } }

.page-services .section-benefits {
  background: linear-gradient(143.87deg, #ffffff 21.1%, #eff9ff 74.08%);
  padding-top: 160px;
  padding-bottom: 140px; }
  .page-services .section-benefits .grid-benefits {
    margin-top: 90px;
    display: flex;
    flex-wrap: wrap; }
    .page-services .section-benefits .grid-benefits .grid-item {
      cursor: initial;
      width: 50%;
      position: relative; }
      .page-services .section-benefits .grid-benefits .grid-item__front {
        transition: 0.3s all ease;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10; }
        .page-services .section-benefits .grid-benefits .grid-item__front-inner {
          width: 300px; }
          .page-services .section-benefits .grid-benefits .grid-item__front-inner .icon {
            margin-bottom: 35px; }
            .page-services .section-benefits .grid-benefits .grid-item__front-inner .icon img,
            .page-services .section-benefits .grid-benefits .grid-item__front-inner .icon svg {
              height: 110px;
              width: auto;
              display: block;
              object-fit: contain; }
          .page-services .section-benefits .grid-benefits .grid-item__front-inner .caption {
            font-family: "HK Nova", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 1.3;
            color: #006AB2; }
      .page-services .section-benefits .grid-benefits .grid-item__back {
        position: relative;
        z-index: 15;
        transform: translateY(15px);
        transition: 0.3s all ease;
        opacity: 0;
        pointer-events: none;
        min-height: 310px;
        background: linear-gradient(214.69deg, #72d2fe -2.73%, #45a6dc 55.53%);
        mix-blend-mode: normal;
        display: flex;
        justify-content: center;
        align-items: center; }
        .page-services .section-benefits .grid-benefits .grid-item__back .content {
          padding-top: 70px;
          padding-bottom: 70px;
          padding-left: 75px;
          padding-right: 60px;
          color: #fff; }
      .page-services .section-benefits .grid-benefits .grid-item:hover .grid-item__back {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0); }
      .page-services .section-benefits .grid-benefits .grid-item:hover .grid-item__front {
        pointer-events: none; }
  .page-services .section-benefits .cta-block {
    margin-top: 140px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
    .page-services .section-benefits .cta-block__left {
      max-width: 540px;
      margin-right: 35px; }
  .page-services .section-benefits .consultation {
    margin-top: 100px; }
  @media (max-width: 991px) {
    .page-services .section-benefits .grid-benefits {
      margin-top: 60px; }
      .page-services .section-benefits .grid-benefits .grid-item__back .content {
        padding-top: 60px;
        padding-bottom: 55px;
        padding-left: 35px;
        padding-right: 30px; }
    .page-services .section-benefits .cta-block {
      align-items: center; }
      .page-services .section-benefits .cta-block__left {
        max-width: 430px; }
    .page-services .section-benefits .consultation {
      margin-top: 70px; } }
  @media (max-width: 767px) {
    .page-services .section-benefits {
      padding-top: 60px;
      padding-bottom: 90px; }
      .page-services .section-benefits .section-title {
        text-align: center; }
      .page-services .section-benefits .grid-benefits {
        margin-top: 50px;
        flex-wrap: wrap;
        flex-direction: column; }
        .page-services .section-benefits .grid-benefits .grid-item {
          width: 100%;
          max-width: 480px;
          margin-left: auto;
          margin-right: auto; }
          .page-services .section-benefits .grid-benefits .grid-item__front-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; }
            .page-services .section-benefits .grid-benefits .grid-item__front-inner .icon {
              display: flex;
              justify-content: center;
              align-items: center; }
            .page-services .section-benefits .grid-benefits .grid-item__front-inner .caption {
              text-align: center; }
          .page-services .section-benefits .grid-benefits .grid-item__front .caption {
            text-align: center; }
          .page-services .section-benefits .grid-benefits .grid-item__back {
            min-height: 280px; }
            .page-services .section-benefits .grid-benefits .grid-item__back .content {
              padding-left: 35px;
              padding-right: 35px;
              padding-top: 60px;
              padding-bottom: 72px;
              line-height: 1.8; }
      .page-services .section-benefits .cta-block {
        margin-top: 60px;
        flex-wrap: wrap; }
        .page-services .section-benefits .cta-block__left {
          max-width: 430px;
          margin-left: auto;
          margin-right: auto;
          text-align: center; }
        .page-services .section-benefits .cta-block__right {
          margin-top: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%; }
      .page-services .section-benefits .consultation {
        margin-top: 60px; } }

.page-services .section-it-support {
  padding-top: 140px;
  padding-bottom: 150px;
  background: linear-gradient(143.87deg, #ffffff 21.1%, #eff9ff 78.9%); }
  .page-services .section-it-support .section-title {
    text-align: center;
    margin-bottom: 80px; }
  .page-services .section-it-support .benefits-slider-wrap {
    margin-top: 100px;
    margin-bottom: 40px; }
  @media (max-width: 991px) {
    .page-services .section-it-support {
      padding-top: 80px;
      padding-bottom: 100px; }
      .page-services .section-it-support .benefits-slider-wrap {
        margin-top: 60px; } }
  @media (max-width: 767px) {
    .page-services .section-it-support .section-title {
      margin-bottom: 20px; }
    .page-services .section-it-support .benefits-slider-wrap {
      margin-top: 30px; } }

.page-services .section-db {
  padding-top: 100px;
  padding-bottom: 160px; }
  .page-services .section-db .section-title {
    text-align: center;
    margin-bottom: 20px; }
  .page-services .section-db .section-subtitle {
    text-align: center; }
    .page-services .section-db .section-subtitle p {
      margin-bottom: 1em; }
      .page-services .section-db .section-subtitle p:last-child {
        margin-bottom: 0; }
  .page-services .section-db .chess-grid {
    margin-top: 80px;
    margin-bottom: 140px; }
  @media (max-width: 991px) {
    .page-services .section-db {
      padding-top: 80px;
      padding-bottom: 100px; }
      .page-services .section-db .section-subtitle br {
        display: none; }
      .page-services .section-db .chess-grid {
        margin-bottom: 80px; } }

.page-services .section-progress {
  background: linear-gradient(143.87deg, #ffffff 21.1%, #eff9ff 78.9%);
  padding-top: 160px;
  padding-bottom: 140px; }
  .page-services .section-progress .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .page-services .section-progress .section-header .section-title {
      margin-right: 90px; }
  .page-services .section-progress .progress-slider-wrap {
    margin-top: 40px; }
    .page-services .section-progress .progress-slider-wrap .slider-progress {
      padding-top: 75px;
      padding-bottom: 60px; }
      .page-services .section-progress .progress-slider-wrap .slider-progress .swiper-slide {
        width: 330px;
        margin-right: 30px; }
        .page-services .section-progress .progress-slider-wrap .slider-progress .swiper-slide .progress-item {
          position: relative; }
          .page-services .section-progress .progress-slider-wrap .slider-progress .swiper-slide .progress-item .number {
            transform: translateY(-50px);
            font-family: "HK Nova", sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 100px;
            line-height: 0.9;
            color: #e5f1fb;
            position: absolute;
            top: 0;
            z-index: 0; }
          .page-services .section-progress .progress-slider-wrap .slider-progress .swiper-slide .progress-item .caption {
            min-height: 85px;
            position: relative;
            z-index: 10;
            margin-bottom: 30px;
            max-width: 290px; }
          .page-services .section-progress .progress-slider-wrap .slider-progress .swiper-slide .progress-item .description {
            position: relative;
            z-index: 10;
            font-family: "HK Nova", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.7;
            color: #7C91A6; }
    .page-services .section-progress .progress-slider-wrap .slider-arrows {
      padding: 15px 0;
      display: flex;
      align-items: center; }
      .page-services .section-progress .progress-slider-wrap .slider-arrows__inner {
        position: relative;
        width: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
      .page-services .section-progress .progress-slider-wrap .slider-arrows .prev,
      .page-services .section-progress .progress-slider-wrap .slider-arrows .next {
        height: 25px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 5px; }
        .page-services .section-progress .progress-slider-wrap .slider-arrows .prev .arrow,
        .page-services .section-progress .progress-slider-wrap .slider-arrows .next .arrow {
          position: relative; }
          .page-services .section-progress .progress-slider-wrap .slider-arrows .prev .arrow:after, .page-services .section-progress .progress-slider-wrap .slider-arrows .prev .arrow:before,
          .page-services .section-progress .progress-slider-wrap .slider-arrows .next .arrow:after,
          .page-services .section-progress .progress-slider-wrap .slider-arrows .next .arrow:before {
            transition: 0.3s all ease; }
          .page-services .section-progress .progress-slider-wrap .slider-arrows .prev .arrow span,
          .page-services .section-progress .progress-slider-wrap .slider-arrows .next .arrow span {
            transition: 0.3s all ease; }
        .page-services .section-progress .progress-slider-wrap .slider-arrows .prev.swiper-button-disabled,
        .page-services .section-progress .progress-slider-wrap .slider-arrows .next.swiper-button-disabled {
          pointer-events: none; }
          .page-services .section-progress .progress-slider-wrap .slider-arrows .prev.swiper-button-disabled .arrow:after, .page-services .section-progress .progress-slider-wrap .slider-arrows .prev.swiper-button-disabled .arrow:before,
          .page-services .section-progress .progress-slider-wrap .slider-arrows .next.swiper-button-disabled .arrow:after,
          .page-services .section-progress .progress-slider-wrap .slider-arrows .next.swiper-button-disabled .arrow:before {
            background-color: #b4c5d7; }
          .page-services .section-progress .progress-slider-wrap .slider-arrows .prev.swiper-button-disabled .arrow span,
          .page-services .section-progress .progress-slider-wrap .slider-arrows .next.swiper-button-disabled .arrow span {
            background-color: #b4c5d7; }
        .page-services .section-progress .progress-slider-wrap .slider-arrows .prev:hover .arrow span,
        .page-services .section-progress .progress-slider-wrap .slider-arrows .next:hover .arrow span {
          width: 52px; }
      .page-services .section-progress .progress-slider-wrap .slider-arrows .prev {
        position: absolute;
        margin-right: 12px;
        right: 50%; }
        .page-services .section-progress .progress-slider-wrap .slider-arrows .prev .arrow span {
          display: block;
          height: 2px;
          background-color: #2D2D2D;
          width: 34px;
          border-radius: 10px; }
        .page-services .section-progress .progress-slider-wrap .slider-arrows .prev .arrow:before {
          display: block;
          content: "";
          width: 2px;
          height: 8px;
          background-color: #2D2D2D;
          border-radius: 10px;
          position: absolute;
          left: 2px;
          bottom: -1px;
          transform: rotate(50deg); }
        .page-services .section-progress .progress-slider-wrap .slider-arrows .prev .arrow:after {
          display: block;
          content: "";
          width: 2px;
          height: 8px;
          background-color: #2D2D2D;
          border-radius: 10px;
          position: absolute;
          left: 2px;
          top: -1px;
          transform: rotate(-50deg); }
      .page-services .section-progress .progress-slider-wrap .slider-arrows .next {
        position: absolute;
        left: 50%; }
        .page-services .section-progress .progress-slider-wrap .slider-arrows .next .arrow span {
          display: block;
          height: 2px;
          background-color: #2D2D2D;
          width: 34px;
          border-radius: 10px; }
        .page-services .section-progress .progress-slider-wrap .slider-arrows .next .arrow:before {
          display: block;
          content: "";
          width: 2px;
          height: 8px;
          background-color: #2D2D2D;
          border-radius: 10px;
          position: absolute;
          right: 2px;
          bottom: -1px;
          transform: rotate(-50deg); }
        .page-services .section-progress .progress-slider-wrap .slider-arrows .next .arrow:after {
          display: block;
          content: "";
          width: 2px;
          height: 8px;
          background-color: #2D2D2D;
          border-radius: 10px;
          position: absolute;
          right: 2px;
          top: -1px;
          transform: rotate(50deg); }
  .page-services .section-progress .notice {
    margin-top: 60px;
    max-width: 630px;
    font-family: "HK Nova", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #2D2D2D; }
  @media (max-width: 991px) {
    .page-services .section-progress {
      padding-bottom: 120px;
      padding-top: 80px; }
      .page-services .section-progress .progress-slider-wrap .slider-progress {
        padding-bottom: 0; }
      .page-services .section-progress .progress-slider-wrap .slider-arrows {
        display: none; } }
  @media (max-width: 767px) {
    .page-services .section-progress .section-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .page-services .section-progress .section-header .section-title {
        margin-left: auto;
        margin-right: auto;
        text-align: center; }
      .page-services .section-progress .section-header .section-subtitle {
        text-align: center; }
    .page-services .section-progress .progress-slider-wrap .slider-progress {
      padding-bottom: 0; }
      .page-services .section-progress .progress-slider-wrap .slider-progress .swiper-slide {
        max-width: 250px; }
        .page-services .section-progress .progress-slider-wrap .slider-progress .swiper-slide .progress-item .caption {
          min-height: 80px;
          font-size: 18px;
          margin-bottom: 20px; } }

.chess-grid {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto; }
  .chess-grid .grid-row {
    display: flex;
    flex-wrap: wrap; }
    .chess-grid .grid-row:nth-child(even) {
      flex-direction: row-reverse; }
      .chess-grid .grid-row:nth-child(even) .grid-item__content {
        justify-content: flex-start;
        padding-left: 90px; }
    .chess-grid .grid-row:nth-child(odd) .grid-item__content {
      justify-content: flex-end;
      padding-right: 9.5%; }
  .chess-grid .grid-item {
    width: 50%; }
    .chess-grid .grid-item__content {
      padding: 60px 20px;
      display: flex;
      align-items: center; }
      .chess-grid .grid-item__content-inner {
        max-width: 400px; }
        .chess-grid .grid-item__content-inner p {
          margin-bottom: 1em; }
          .chess-grid .grid-item__content-inner p:last-child {
            margin-bottom: 0; }
        .chess-grid .grid-item__content-inner strong,
        .chess-grid .grid-item__content-inner a {
          color: #006AB2;
          font-weight: 500; }
        .chess-grid .grid-item__content-inner ul {
          margin-bottom: 10px; }
          .chess-grid .grid-item__content-inner ul:last-child {
            margin-bottom: 0; }
          .chess-grid .grid-item__content-inner ul li {
            position: relative; }
            .chess-grid .grid-item__content-inner ul li:before {
              display: inline-block;
              content: "•";
              font-size: inherit;
              color: inherit;
              margin-right: 10px; }
    .chess-grid .grid-item__image {
      max-height: 450px;
      position: relative; }
      .chess-grid .grid-item__image img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .chess-grid .grid-item__image:after {
        padding-bottom: 62.5%;
        display: block;
        content: ""; }
  @media (max-width: 991px) {
    .chess-grid .grid-item__content-inner {
      max-width: 300px; }
    .chess-grid .grid-row:nth-child(even) .grid-item__content {
      justify-content: center;
      padding-left: 40px;
      padding-right: 40px; }
    .chess-grid .grid-row:nth-child(odd) .grid-item__content {
      justify-content: center;
      padding-left: 40px;
      padding-right: 40px; } }
  @media (max-width: 660px) {
    .chess-grid .grid-row {
      flex-direction: column; }
    .chess-grid .grid-item {
      width: 100%; }
      .chess-grid .grid-item__content {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 15px;
        padding-right: 15px; } }

.benefits-slider-wrap .arrows-wrap {
  padding-bottom: 20px;
  position: relative;
  z-index: 35;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  .benefits-slider-wrap .arrows-wrap .slider-arrows {
    display: flex;
    align-items: center; }
    .benefits-slider-wrap .arrows-wrap .slider-arrows__inner {
      position: relative;
      width: 85px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .benefits-slider-wrap .arrows-wrap .slider-arrows .prev,
    .benefits-slider-wrap .arrows-wrap .slider-arrows .next {
      height: 25px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 5px; }
      .benefits-slider-wrap .arrows-wrap .slider-arrows .prev .arrow,
      .benefits-slider-wrap .arrows-wrap .slider-arrows .next .arrow {
        position: relative; }
        .benefits-slider-wrap .arrows-wrap .slider-arrows .prev .arrow:after, .benefits-slider-wrap .arrows-wrap .slider-arrows .prev .arrow:before,
        .benefits-slider-wrap .arrows-wrap .slider-arrows .next .arrow:after,
        .benefits-slider-wrap .arrows-wrap .slider-arrows .next .arrow:before {
          transition: 0.3s all ease; }
        .benefits-slider-wrap .arrows-wrap .slider-arrows .prev .arrow span,
        .benefits-slider-wrap .arrows-wrap .slider-arrows .next .arrow span {
          transition: 0.3s all ease; }
      .benefits-slider-wrap .arrows-wrap .slider-arrows .prev.swiper-button-disabled,
      .benefits-slider-wrap .arrows-wrap .slider-arrows .next.swiper-button-disabled {
        pointer-events: none; }
        .benefits-slider-wrap .arrows-wrap .slider-arrows .prev.swiper-button-disabled .arrow:after, .benefits-slider-wrap .arrows-wrap .slider-arrows .prev.swiper-button-disabled .arrow:before,
        .benefits-slider-wrap .arrows-wrap .slider-arrows .next.swiper-button-disabled .arrow:after,
        .benefits-slider-wrap .arrows-wrap .slider-arrows .next.swiper-button-disabled .arrow:before {
          background-color: #b4c5d7; }
        .benefits-slider-wrap .arrows-wrap .slider-arrows .prev.swiper-button-disabled .arrow span,
        .benefits-slider-wrap .arrows-wrap .slider-arrows .next.swiper-button-disabled .arrow span {
          background-color: #b4c5d7; }
      .benefits-slider-wrap .arrows-wrap .slider-arrows .prev:hover .arrow span,
      .benefits-slider-wrap .arrows-wrap .slider-arrows .next:hover .arrow span {
        width: 52px; }
    .benefits-slider-wrap .arrows-wrap .slider-arrows .prev {
      position: absolute;
      margin-right: 12px;
      right: 50%; }
      .benefits-slider-wrap .arrows-wrap .slider-arrows .prev .arrow span {
        display: block;
        height: 2px;
        background-color: #2D2D2D;
        width: 34px;
        border-radius: 10px; }
      .benefits-slider-wrap .arrows-wrap .slider-arrows .prev .arrow:before {
        display: block;
        content: "";
        width: 2px;
        height: 8px;
        background-color: #2D2D2D;
        border-radius: 10px;
        position: absolute;
        left: 2px;
        bottom: -1px;
        transform: rotate(50deg); }
      .benefits-slider-wrap .arrows-wrap .slider-arrows .prev .arrow:after {
        display: block;
        content: "";
        width: 2px;
        height: 8px;
        background-color: #2D2D2D;
        border-radius: 10px;
        position: absolute;
        left: 2px;
        top: -1px;
        transform: rotate(-50deg); }
    .benefits-slider-wrap .arrows-wrap .slider-arrows .next {
      position: absolute;
      left: 50%; }
      .benefits-slider-wrap .arrows-wrap .slider-arrows .next .arrow span {
        display: block;
        height: 2px;
        background-color: #2D2D2D;
        width: 34px;
        border-radius: 10px; }
      .benefits-slider-wrap .arrows-wrap .slider-arrows .next .arrow:before {
        display: block;
        content: "";
        width: 2px;
        height: 8px;
        background-color: #2D2D2D;
        border-radius: 10px;
        position: absolute;
        right: 2px;
        bottom: -1px;
        transform: rotate(-50deg); }
      .benefits-slider-wrap .arrows-wrap .slider-arrows .next .arrow:after {
        display: block;
        content: "";
        width: 2px;
        height: 8px;
        background-color: #2D2D2D;
        border-radius: 10px;
        position: absolute;
        right: 2px;
        top: -1px;
        transform: rotate(50deg); }

.benefits-slider-wrap .slider-benefits {
  padding-top: 40px;
  padding-bottom: 60px; }
  .benefits-slider-wrap .slider-benefits .swiper-slide {
    width: 280px;
    height: 190px;
    background: #ffffff;
    box-shadow: 0px 24px 44px rgba(0, 106, 178, 0.05);
    border: 1px solid #c7e4f5;
    margin-right: 20px;
    transition: 0.3s all ease; }
    .benefits-slider-wrap .slider-benefits .swiper-slide:hover {
      transform: translateY(-10px); }
    .benefits-slider-wrap .slider-benefits .swiper-slide .benefit-item {
      padding-top: 32px;
      padding-left: 45px;
      padding-right: 45px; }
      .benefits-slider-wrap .slider-benefits .swiper-slide .benefit-item .number {
        margin-bottom: 20px;
        position: relative;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        overflow: hidden;
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 1;
        color: #006AB2; }
        .benefits-slider-wrap .slider-benefits .swiper-slide .benefit-item .number:after {
          display: block;
          content: "";
          width: 100%;
          height: 100%;
          background: linear-gradient(214.69deg, #72d2fe -2.73%, #45a6dc 55.53%);
          opacity: 0.2;
          position: absolute;
          left: 0;
          right: 0;
          top: 0%;
          bottom: 0; }
      .benefits-slider-wrap .slider-benefits .swiper-slide .benefit-item .benefit {
        font-size: 18px; }

@media (max-width: 991px) {
  .benefits-slider-wrap .arrows-wrap {
    display: none; } }

@media (max-width: 767px) {
  .benefits-slider-wrap .slider-benefits .swiper-slide {
    width: 240px;
    height: auto;
    min-height: 190px; }
    .benefits-slider-wrap .slider-benefits .swiper-slide .benefit-item {
      padding-left: 35px;
      padding-right: 35px; }
    .benefits-slider-wrap .slider-benefits .swiper-slide .benefit {
      font-size: 16px; } }

.text-content .section-title {
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 30px !important; }

.text-content .section-subtitle {
  text-align: center;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto; }

.text-content .btn-wrap {
  margin-bottom: 60px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }

.divider {
  background-color: #cff0ff;
  margin: 0;
  display: block;
  width: 100%;
  height: 1px;
  border: none; }

.page-references .page-hero-section {
  padding-top: 180px;
  padding-bottom: 180px;
  display: flex;
  align-items: center; }
  .page-references .page-hero-section .sub-heading {
    max-width: 630px;
    margin-bottom: 0;
    margin-top: 20px; }
  @media (max-width: 991px) {
    .page-references .page-hero-section {
      padding-bottom: 120px;
      min-height: unset; }
      .page-references .page-hero-section .heading {
        text-align: left; }
      .page-references .page-hero-section .sub-heading {
        text-align: left; } }

.page-references .section-refs {
  background: linear-gradient(143.87deg, #FFFFFF 21.1%, #EFF9FF 74.08%);
  padding-top: 140px;
  padding-bottom: 140px; }
  .page-references .section-refs .link-wrap {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center; }
  @media (max-width: 991px) {
    .page-references .section-refs {
      padding-top: 130px;
      padding-bottom: 120px; }
      .page-references .section-refs .link-wrap {
        margin-top: 60px; } }
  @media (max-width: 767px) {
    .page-references .section-refs {
      padding-top: 80px;
      padding-bottom: 90px; }
      .page-references .section-refs .section-title {
        text-align: center; }
      .page-references .section-refs .section-subtitle {
        text-align: center; }
      .page-references .section-refs .link-wrap .link__caption {
        text-decoration: underline; }
      .page-references .section-refs .link-wrap .link__inner:after {
        display: none; }
      .page-references .section-refs .link-wrap .link__icon {
        display: none; } }

.page-references .section-case-studies {
  padding-top: 140px;
  padding-bottom: 230px; }
  .page-references .section-case-studies .section-headers {
    display: flex;
    justify-content: space-between; }
    .page-references .section-case-studies .section-headers .section-title {
      margin-right: 90px; }
    .page-references .section-case-studies .section-headers .section-subtitle {
      max-width: 535px; }
  .page-references .section-case-studies .case-study-grid {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -7.5px;
    margin-right: -7.5px; }
    .page-references .section-case-studies .case-study-grid .case-study-item {
      width: calc(100% / 3 - 15px);
      margin: 7.5px;
      position: relative;
      transition: 0.3s all ease; }
      .page-references .section-case-studies .case-study-grid .case-study-item:after {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        background: linear-gradient(214.69deg, #72D2FE -2.73%, #45A6DC 55.53%);
        opacity: 0.85; }
      .page-references .section-case-studies .case-study-grid .case-study-item .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        z-index: 1; }
      .page-references .section-case-studies .case-study-grid .case-study-item .overlay {
        background: linear-gradient(214.69deg, #72D2FE -2.73%, #45A6DC 55.53%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        z-index: 5;
        opacity: 0;
        transition: 0.3s all ease; }
      .page-references .section-case-studies .case-study-grid .case-study-item__inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        overflow: hidden;
        position: relative;
        z-index: 15;
        padding-top: 75px;
        padding-left: 50px;
        padding-right: 40px;
        padding-bottom: 30px; }
        .page-references .section-case-studies .case-study-grid .case-study-item__inner .content {
          color: #fff;
          display: block;
          margin-bottom: 40px;
          font-size: 18px; }
        .page-references .section-case-studies .case-study-grid .case-study-item__inner .link {
          transition: 0.3s all ease;
          opacity: 0;
          transform: translateY(15px); }
      .page-references .section-case-studies .case-study-grid .case-study-item:hover {
        box-shadow: 0px 14px 54px rgba(0, 106, 178, 0.3); }
        .page-references .section-case-studies .case-study-grid .case-study-item:hover .overlay {
          opacity: 1; }
        .page-references .section-case-studies .case-study-grid .case-study-item:hover .case-study-item__inner .link {
          opacity: 1;
          transform: translateY(0); }
  .page-references .section-case-studies .link-wrap {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .page-references .section-case-studies .case-study-description {
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
    text-align: center; }
  .page-references .section-case-studies .link-consultation {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-content: center; }
  @media (max-width: 991px) {
    .page-references .section-case-studies {
      padding-top: 120px;
      padding-bottom: 160px; }
      .page-references .section-case-studies .section-headers .section-subtitle {
        max-width: 340px; }
      .page-references .section-case-studies .case-study-grid .case-study-item {
        width: calc(100% / 2 - 15px); } }
  @media (max-width: 767px) {
    .page-references .section-case-studies {
      padding-top: 80px;
      padding-bottom: 95px; }
      .page-references .section-case-studies .section-headers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center; }
        .page-references .section-case-studies .section-headers .section-title {
          text-align: center;
          margin-left: auto;
          margin-right: auto; }
      .page-references .section-case-studies .case-study-grid {
        margin-top: 40px; }
      .page-references .section-case-studies .case-study-description {
        margin-top: 40px; }
      .page-references .section-case-studies .link-consultation {
        margin-top: 60px; }
        .page-references .section-case-studies .link-consultation .link__caption {
          text-decoration: underline; }
        .page-references .section-case-studies .link-consultation .link__inner:after {
          display: none; }
        .page-references .section-case-studies .link-consultation .link__icon {
          display: none; } }
  @media (max-width: 565px) {
    .page-references .section-case-studies .case-study-grid .case-study-item {
      width: calc(100% - 15px); }
      .page-references .section-case-studies .case-study-grid .case-study-item__inner {
        padding-left: 30px;
        padding-right: 18px;
        padding-bottom: 50px; } }

.modal-scroller {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.case-study-modal {
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 150;
  position: fixed;
  display: block; }
  .case-study-modal .modal-wrap {
    margin-top: 90px;
    margin-bottom: 200px;
    position: relative;
    max-width: calc(100% - 80px);
    width: 1080px;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto; }
  .case-study-modal .popup-header {
    height: 420px;
    width: 100%;
    position: relative; }
    .case-study-modal .popup-header .close-modal {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer; }
    .case-study-modal .popup-header img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .case-study-modal .popup-body {
    padding-bottom: 200px;
    padding-top: 85px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff; }
    .case-study-modal .popup-body .title {
      display: block;
      margin-bottom: 40px; }
    .case-study-modal .popup-body .content {
      max-width: 720px;
      margin-left: auto;
      margin-right: auto; }
    .case-study-modal .popup-body .content__footer {
      margin-top: 50px; }
      .case-study-modal .popup-body .content__footer .notice {
        margin-bottom: 40px; }
  @media (max-width: 1199px) {
    .case-study-modal .popup-body {
      padding-left: 60px;
      padding-right: 60px;
      padding-bottom: 85px; } }
  @media (max-width: 767px) {
    .case-study-modal .popup-header {
      height: 280px; }
      .case-study-modal .popup-header .close-modal {
        right: 20px;
        top: 20px; }
        .case-study-modal .popup-header .close-modal svg {
          height: 20px;
          width: auto;
          display: block;
          object-fit: contain; }
    .case-study-modal .modal-wrap {
      max-width: 100%;
      margin-top: 0;
      margin-bottom: 0; }
    .case-study-modal .popup-body {
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 120px; }
      .case-study-modal .popup-body .title {
        font-size: 26px; }
      .case-study-modal .popup-body .content__footer .notice {
        font-size: 18px;
        text-align: center; }
      .case-study-modal .popup-body .content__footer .link__caption {
        text-decoration: underline; }
      .case-study-modal .popup-body .content__footer .link__inner:after {
        display: none; }
      .case-study-modal .popup-body .content__footer .link__icon {
        display: none; } }

.page-about .page-hero-section {
  padding-top: 180px;
  padding-bottom: 180px;
  display: flex;
  align-items: center; }
  .page-about .page-hero-section .sub-heading {
    max-width: 600px;
    margin-bottom: 0;
    margin-top: 20px; }
  @media (max-width: 991px) {
    .page-about .page-hero-section {
      padding-bottom: 120px;
      min-height: unset; }
      .page-about .page-hero-section .heading {
        text-align: left; }
      .page-about .page-hero-section .sub-heading {
        text-align: left; } }

.page-about .section-about {
  padding-top: 140px;
  padding-bottom: 120px;
  background: linear-gradient(143.87deg, #FFFFFF 21.1%, #EFF9FF 74.08%); }
  .page-about .section-about .section-headers {
    display: flex;
    justify-content: space-between; }
    .page-about .section-about .section-headers .section-title {
      margin-right: 90px; }
      .page-about .section-about .section-headers .section-title span {
        color: #006AB2; }
    .page-about .section-about .section-headers .section-subtitle {
      max-width: 540px; }
  .page-about .section-about .slider-subsection {
    margin-top: 140px; }
    .page-about .section-about .slider-subsection .heading-flex {
      justify-content: space-between;
      align-items: center;
      display: flex; }
      .page-about .section-about .slider-subsection .heading-flex .headers {
        max-width: 400px; }
    .page-about .section-about .slider-subsection .link-bot {
      display: none; }
  @media (max-width: 991px) {
    .page-about .section-about {
      padding-top: 80px;
      padding-bottom: 120px; }
      .page-about .section-about .section-headers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .page-about .section-about .section-headers .section-title {
          text-align: center;
          max-width: 560px;
          margin-left: auto;
          margin-right: auto; }
        .page-about .section-about .section-headers .section-subtitle {
          max-width: 100%;
          margin-top: 30px; }
      .page-about .section-about .slider-subsection {
        margin-top: 100px; } }
  @media (max-width: 767px) {
    .page-about .section-about {
      padding-top: 60px;
      padding-bottom: 60px; }
      .page-about .section-about .section-headers .section-subtitle {
        margin-top: 20px; }
      .page-about .section-about .slider-subsection {
        margin-top: 60px; }
        .page-about .section-about .slider-subsection .heading-flex .link {
          display: none; }
        .page-about .section-about .slider-subsection .heading-flex .headers {
          text-align: center;
          max-width: 100%;
          width: 100%; }
        .page-about .section-about .slider-subsection .link-bot {
          margin-top: 50px;
          display: inline-flex;
          position: relative;
          left: 50%;
          transform: translateX(-50%); } }

.page-about .section-job {
  background: linear-gradient(143.87deg, #FFFFFF 21.1%, #EFF9FF 74.08%);
  padding-top: 140px;
  padding-bottom: 180px; }
  .page-about .section-job .job-block {
    margin-bottom: 80px; }
  .page-about .section-job .team-block {
    align-items: flex-start;
    margin-top: 70px; }
  .page-about .section-job .pre-footer-section {
    padding-top: 140px; }
    .page-about .section-job .pre-footer-section .headings {
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end; }
      .page-about .section-job .pre-footer-section .headings .section-title {
        max-width: 510px; }
      .page-about .section-job .pre-footer-section .headings .link {
        margin-bottom: 20px; }
    .page-about .section-job .pre-footer-section .link-bot {
      display: none; }
    .page-about .section-job .pre-footer-section .content {
      max-width: 720px; }
      .page-about .section-job .pre-footer-section .content p {
        margin-bottom: 1em; }
    @media (max-width: 991px) {
      .page-about .section-job .pre-footer-section {
        padding-top: 80px; }
        .page-about .section-job .pre-footer-section .headings {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px; }
          .page-about .section-job .pre-footer-section .headings .section-title {
            text-align: center;
            margin-left: auto;
            margin-right: auto; }
          .page-about .section-job .pre-footer-section .headings .link {
            display: none; }
        .page-about .section-job .pre-footer-section .content {
          margin-top: 40px;
          margin-bottom: 40px; }
        .page-about .section-job .pre-footer-section .link-bot {
          display: inline-flex;
          position: relative;
          left: 50%;
          transform: translateX(-50%); } }
  @media (max-width: 991px) {
    .page-about .section-job {
      padding-top: 120px;
      padding-bottom: 120px; }
      .page-about .section-job .job-block {
        margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .page-about .section-job {
      padding-top: 80px;
      padding-bottom: 100px; } }

.two-cols-block {
  display: flex;
  align-items: center; }
  .two-cols-block .col-left {
    width: 50%; }
  .two-cols-block .col-right {
    width: 50%; }
    .two-cols-block .col-right.col-content {
      padding-left: 90px; }
  .two-cols-block .col-content .section-title {
    margin-bottom: 30px; }
  .two-cols-block .col-content .description p {
    margin-bottom: 1.5rem; }
  .two-cols-block .acc-item {
    cursor: pointer;
    transition: 0.3s all ease;
    border: 1px solid transparent; }
    .two-cols-block .acc-item__header {
      transition: 0.3s all ease;
      padding-left: 50px;
      padding-right: 30px;
      padding-top: 25px;
      padding-bottom: 25px;
      display: flex;
      align-items: center; }
      .two-cols-block .acc-item__header .number {
        transition: 0.3s all ease;
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: #7C91A6;
        margin-right: 15px; }
      .two-cols-block .acc-item__header .label {
        transition: 0.3s all ease;
        font-family: "HK Nova", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        color: #7C91A6; }
    .two-cols-block .acc-item__body {
      padding-left: 90px;
      font-size: 14px;
      padding-right: 30px;
      display: none;
      padding-bottom: 40px; }
    .two-cols-block .acc-item.active {
      border: 1px solid #C7E4F4;
      background-color: #fff;
      box-shadow: 0px 14px 54px rgba(0, 106, 178, 0.15); }
      .two-cols-block .acc-item.active .acc-item__header .number {
        color: #006AB2; }
      .two-cols-block .acc-item.active .acc-item__header .label {
        color: #2D2D2D; }
      .two-cols-block .acc-item.active .acc-item__body {
        height: auto; }
  @media (max-width: 991px) {
    .two-cols-block {
      flex-wrap: wrap; }
      .two-cols-block .col-left {
        width: 100%; }
      .two-cols-block .col-right {
        width: 100%; }
        .two-cols-block .col-right.col-content {
          padding-left: 0; }
      .two-cols-block .col-image img {
        max-height: 400px;
        object-fit: cover;
        width: 100%;
        height: auto; }
      .two-cols-block .col-content {
        padding-top: 60px; }
        .two-cols-block .col-content .section-title {
          text-align: center; }
      .two-cols-block .acc-item {
        max-width: 450px;
        margin-left: auto;
        margin-right: auto; } }
  @media (max-width: 767px) {
    .two-cols-block .acc-item__header {
      padding-top: 25px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 25px; }
      .two-cols-block .acc-item__header .label {
        font-size: 18px; }
    .two-cols-block .acc-item__body {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px; } }

.testimonial-block {
  padding-top: 65px;
  padding-bottom: 85px;
  position: relative; }
  .testimonial-block .review-item__message {
    display: flex; }
    .testimonial-block .review-item__message .icon {
      margin-right: 40px; }
      .testimonial-block .review-item__message .icon svg {
        height: 40px;
        width: auto;
        display: block;
        object-fit: contain; }
    .testimonial-block .review-item__message .content {
      max-width: 630px; }
  .testimonial-block .review-item__author {
    margin-top: 40px;
    display: flex;
    align-items: center; }
    .testimonial-block .review-item__author .avatar {
      margin-right: 30px; }
      .testimonial-block .review-item__author .avatar img {
        overflow: hidden;
        width: 90px;
        height: 90px;
        border-radius: 100%;
        display: block;
        object-fit: contain; }
    .testimonial-block .review-item__author .work-time {
      color: #7C91A6; }
  .testimonial-block .map-wrap {
    top: 0;
    bottom: 0;
    position: absolute;
    width: 50%;
    left: 50%;
    pointer-events: none; }
    .testimonial-block .map-wrap .relative-map-wrap {
      width: 100%;
      position: relative; }
      .testimonial-block .map-wrap .relative-map-wrap img {
        position: absolute;
        left: 0;
        max-width: unset; }
  @media (max-width: 991px) {
    .testimonial-block .map-wrap {
      width: 100%;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
      .testimonial-block .map-wrap .relative-map-wrap {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .testimonial-block .map-wrap .relative-map-wrap img {
          height: 100%;
          display: block; } }
  @media (max-width: 767px) {
    .testimonial-block {
      padding-top: 20px;
      padding-bottom: 20px; }
      .testimonial-block .review-item__message {
        flex-direction: column;
        max-width: 480px;
        margin-left: auto;
        margin-right: auto; }
        .testimonial-block .review-item__message .icon {
          margin-bottom: 20px; }
      .testimonial-block .review-item__author .avatar {
        margin-right: 20px; }
        .testimonial-block .review-item__author .avatar img {
          width: 70px;
          height: 70px; } }
  @media (max-width: 370px) {
    .testimonial-block .review-item__message .content__text {
      font-size: 18px; } }

.page-404 .page-hero-section {
  display: flex;
  align-items: center; }
  .page-404 .page-hero-section .heading {
    margin-bottom: 20px; }
  .page-404 .page-hero-section .sub-heading {
    max-width: 640px; }
  .page-404 .page-hero-section .links-wrap {
    margin-top: 40px; }

.page-policy .page-hero-section {
  display: flex;
  align-items: center; }
  .page-policy .page-hero-section .heading {
    margin-bottom: 20px; }
  .page-policy .page-hero-section .sub-heading {
    max-width: 640px; }
  .page-policy .page-hero-section .links-wrap {
    margin-top: 40px; }
  @media (max-width: 767px) {
    .page-policy .page-hero-section {
      min-height: 300px; } }

.page-policy .section-policy {
  padding-top: 80px;
  padding-bottom: 20px; }
  .page-policy .section-policy .policy-row {
    display: flex;
    justify-content: space-between; }
    .page-policy .section-policy .policy-row .col-content {
      max-width: 640px; }
    .page-policy .section-policy .policy-row .sidebar {
      padding-left: 30px;
      width: calc(100% - 640px);
      display: flex;
      justify-content: center; }
      .page-policy .section-policy .policy-row .sidebar .links li {
        margin-bottom: 15px; }
        .page-policy .section-policy .policy-row .sidebar .links li a {
          transition: 0.3s all ease; }
        .page-policy .section-policy .policy-row .sidebar .links li.current a, .page-policy .section-policy .policy-row .sidebar .links li:hover a {
          text-decoration: underline; }
  @media (max-width: 991px) {
    .page-policy .section-policy .policy-row .col-content {
      max-width: 480px; }
    .page-policy .section-policy .policy-row .sidebar {
      padding-left: 30px;
      width: calc(100% - 480px); } }
  @media (max-width: 767px) {
    .page-policy .section-policy {
      padding-top: 60px; }
      .page-policy .section-policy .policy-row {
        flex-direction: column-reverse;
        flex-wrap: wrap; }
        .page-policy .section-policy .policy-row .col-content {
          max-width: 100%;
          padding-top: 30px; }
        .page-policy .section-policy .policy-row .sidebar {
          width: 100%;
          padding-left: 0; }
          .page-policy .section-policy .policy-row .sidebar .links li {
            text-align: center; }
            .page-policy .section-policy .policy-row .sidebar .links li a {
              text-align: center; } }
