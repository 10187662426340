// Basic
.clearfix {
  &:before, &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

.aligncenter, .center {
  text-align: center;
}

.alignleft, .left {
  text-align: left;
}

.alignright, .right {
  text-align: right;
}


.white {
  color: $color-white;
}


.above-lines {
  z-index: 25;
}

.no-js {
  visibility: hidden;
  opacity: 0;
}

.js {
  visibility: visible;
  opacity: 1;
}

.swal2-styled.swal2-confirm{
  background-color: $color-blue!important;
  color: $color-white!important;
  border: 0!important;
}

.swal2-html-container{
  max-width: 75%;
  margin: 0 auto;
  line-height: 1.6;
}