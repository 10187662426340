@font-face {
  font-family: 'HK Nova';
  src: url('../fonts/HK-Nova/HKNova-Medium.eot');
  src: url('../fonts/HK-Nova/HKNova-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HK-Nova/HKNova-Medium.woff2') format('woff2'),
  url('../fonts/HK-Nova/HKNova-Medium.woff') format('woff'),
  url('../fonts/HK-Nova/HKNova-Medium.ttf') format('truetype'),
  url('../fonts/HK-Nova/HKNova-Medium.svg#HKNova-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  src: url('../fonts/ProximaNova/ProximaNova-Semibold.eot');
  src: url('../fonts/ProximaNova/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNova-Semibold.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}