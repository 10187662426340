// Internal Page Header
.page-hero-section {
  width: 100%;
  position: relative;
  min-height: 600px;
  height: auto;
  z-index: 10;
  padding-top: calc(60px + 70px + 50px);
  padding-bottom: 100px;

  // Background
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    z-index: 1;
  }

  // Content
  .container {
    position: relative;
    z-index: 10;
  }

  .heading {
    max-width: 880px;
  }

  .sub-heading {
    margin-bottom: 20px;
  }


  .links-wrap {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;

    .link {
      margin-right: 55px;

      &:last-child {
        margin-right: 0;
      }

    }
  }


  @media (max-width: 991px) {
    padding-bottom: 120px;
    .links-wrap {
      margin-top: 40px;

      .link {
        margin-bottom: 40px;
      }
    }
  }


  @media (max-width: 767px) {
    padding-bottom: 80px;
    padding-top: calc(60px + 90px);
    .heading {
      text-align: center;
    }
    .sub-heading {
      text-align: center;
      margin-bottom: 35px;
    }
    .links-wrap{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .link{
        margin-left: auto!important;
        margin-right: auto!important;
      }
    }
  }


}
